import { StringConstants } from "global/constants/StringConstants";

class UrlConstants extends StringConstants {
  PROD = true;

  url_prod = "https://services.xlconstructionllc.com";
  // url_dev = "https://api-uat.softsages.com";
  url_dev = "http://localhost:4000";

  url = this.PROD ? this.url_prod : this.url_dev;

  authURL = "auth";
  subcontractorURL = "subcontractor";

  // Auth
  REFRESHTOKEN = `${this.url}/${this.authURL}/refresh/token`;
  LOGIN = `${this.url}/${this.authURL}/login`;

  //subcontractor
  GETSUBCONTRACTORS = `${this.url}/${this.subcontractorURL}/all`;
  GETSUBCONTRACTOR = `${this.url}/${this.subcontractorURL}`;
  ADDORUPDATESUBCONTRACTOR = `${this.url}/${this.subcontractorURL}`;

  webhook_URL = "";
}

let urls = new UrlConstants();
export default urls;
