import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  InputAdornment,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import viewpaths from "global/constants/ViewPathConstants";
import { useAppSelector } from "hooks/use-app-select";
import { useRef, useState } from "react";
import { loginAction, selectAuthenticated } from "redux/authSlice";
import history from "utils/history";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import { login } from "screens/Auth/AuthService";
import { useAppDispatch } from "hooks/use-app-dispatch";
import {
  convertResourceToObjectFormat,
  handleErrorMessage,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import urls from "global/constants/UrlConstants";
import ReCAPTCHA from "react-google-recaptcha";
import strings from "global/constants/StringConstants";
import { CustomInput } from "global/components";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoginFields } from "models/interfaces";
import { useAuthRouteRedirection } from "layouts/Config";
import { useTitle } from "utils/UseTitle";
import title from "global/constants/TitleConstants";
import defaultImage from "assets/icons1/avatars/default.png";
import { updatePreference } from "redux/preferencesSlice";

const Login = () => {
  useTitle(title.App);
  const dispatch = useAppDispatch();
  const reRef = useRef<ReCAPTCHA | null>(null);
  const emailRegex = strings.regex;
  const isLoggedIn = useAppSelector(selectAuthenticated);
  const authRoute = useAuthRouteRedirection();
  const [processing, setProcessing] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as LoginFields);
  const [visibility, setVisibility] = useState(false);

  const handleChange = (event: any) => {
    setCredentials({
      ...credentials,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleValidation = () => {
    let errors = credentials;
    let isValid = true;
    const emailRegex = strings.regex;
    const email = credentials.email.value;
    const password = credentials.password.value;

    if (!email && !password) {
      errors.email.error = "Email is required";
      errors.password.error = "Password is required";
      isValid = false;
    }
    if (!email) {
      errors.email.error = "Please enter email";
      isValid = false;
    }
    if (email) {
      if (!emailRegex.test(email)) {
        errors.email.error = "Please enter valid email";
        isValid = false;
      }
    }
    if (!password) {
      errors.password.error = "Please enter password";
      isValid = false;
    }
    if (password && password.length < 8) {
      errors.password.error = "Password must be 8 character long";
      isValid = false;
    }
    setCredentials({ ...errors });
    return isValid;
  };

  const doLogin = async () => {
    try {
      if (handleValidation()) {
        setProcessing(true);
        let captchaToken: any;
        if (urls.PROD) {
          captchaToken = await reRef.current?.executeAsync();
        }
        reRef.current && reRef.current.reset();
        const user = await login(
          credentials.email.value,
          credentials.password.value,
          captchaToken
        );

        dispatch(
          loginAction({
            authenticated: true,
            accessToken: user.auth.idToken,
            refreshToken: user.auth.refreshToken,
            userName: user.auth.name,
            email: user.auth.email,
            userRole: user.auth.role,
            profileImage: user.auth.profilePicture
              ? "data:image/png;base64," + user.profilePicture
              : defaultImage,
          })
        );
        dispatch(
          updatePreference({
            layout: user.userPreferences?.layout ?? "vertical",
            theme: user.userPreferences?.theme ?? "light",
          })
        );

        setProcessing(false);
      }
    } catch (error: any) {
      handleErrorMessage(error);
      setProcessing(false);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      doLogin();
    }
  };

  const loginScreen = () => {
    return (
      <Card elevation={16}>
        <CardHeader
          subheader={
            <Typography color="text.secondary" variant="body2">
              Please enter your email and password.
            </Typography>
          }
          sx={{ pb: 0 }}
          title={
            <Typography color="text.secondary" variant="h4">
              Welcome back!
            </Typography>
          }
        />
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <CustomInput
                autoFocus
                placeHolder="Email Address"
                name="email"
                onChange={handleChange}
                type="email"
                value={credentials.email.value}
                disabled={processing}
                propsToInputElement={{ maxLength: 50 }}
                error={
                  !isTruthy(credentials.email.value) && credentials.email.error
                }
                onKeyPress={handleKeypress}
                helperText={
                  !isTruthy(credentials.email.value)
                    ? credentials.email.error
                    : ""
                }
              />
              {!emailRegex.test(credentials.email.value) &&
                credentials.email.value.length > 0 && (
                  <FormHelperText error sx={{ paddingLeft: "14px" }}>
                    Please enter valid email
                  </FormHelperText>
                )}
            </Box>
            <CustomInput
              placeHolder="Password"
              name="password"
              onChange={handleChange}
              type={visibility ? "text" : "password"}
              value={credentials.password.value}
              sx={{
                "& .MuiInputBase-input": { padding: "13px 13px" },
                "& input: : -ms-reveal": { display: "none" },
              }}
              disabled={processing}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setVisibility(!visibility)}
                    sx={{ cursor: "pointer" }}
                  >
                    <SvgIcon>
                      {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              propsToInputElement={{ maxLength: 30 }}
              error={
                credentials.password.value.length < 8
                  ? credentials.password.error
                  : null
              }
              helperText={credentials.password.error}
            />
          </Stack>
          <Button
            fullWidth
            size="medium"
            sx={{ mt: 2 }}
            type="submit"
            variant="contained"
            onClick={doLogin}
            disabled={processing}
          >
            Log In
          </Button>
        </CardContent>
      </Card>
    );
  };

  if (isLoggedIn) {
    history.push(authRoute);
    return null;
  } else {
    return (
      <AuthLayout>
        {loginScreen()}
        {/* {urls.PROD && (
          <ReCAPTCHA
            sitekey={strings.RECAPTCHA_SITE_KEY}
            ref={reRef}
            size="invisible"
          />
        )} */}
      </AuthLayout>
    );
  }
};

export default Login;
