const viewApplicantStyles = {
  labelText: {
    color: "#ADADAD",
  },
  valueText: {
    color: "#383838",
  },
  helpDesk: {
    color: "#74787E",
  },
  comment: {
    display: "inline-block",
    wordBreak: "break-word",
    maxWidth: "300px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  titleStyle: {
    textAlign: "center",
    marginTop : "3px"
  },
};
export default viewApplicantStyles;
