import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";
import { Comment } from "./applicantTypes";
import { SortingConfig } from "models/interfaces";

export const getAddComments = async (comments: any, id: any) => {
  const url = `${urls.GETSUBCONTRACTOR}/${id}/comment`;
  try {
    const callParams = await getCallParams("POST", { comment: comments });
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const saveApplicant = async (body: any) => {
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.LOGIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const editApplicant = async (body: any) => {
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(
      urls.ADDORUPDATESUBCONTRACTOR,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getActiveCount = async (
  page: number,
  rowsPerPage: number,
  searchQuery: string,
  sortParam: SortingConfig[]
) => {
  const url = `${urls.GETSUBCONTRACTORS}`;
  try {
    const body = {
      page,
      rowsPerPage,
      sortParam,
      searchBy: "field",
      searchQuery,
    };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getApplicantById = async (id: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.GETSUBCONTRACTOR}/${id}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteApplicant = async (id: string[]) => {
  try {
    const callParams = await getCallParams("PATCH", id);
    const response = await makeCall(urls.LOGIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const inactivateApplicant = async (id: string[]) => {
  try {
    const callParams = await getCallParams("PATCH", id);
    const response = await makeCall(urls.LOGIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const reactivateApplicant = async (id: string[]) => {
  try {
    const callParams = await getCallParams("PATCH", id);
    const response = await makeCall(urls.LOGIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doPinUnpinComment = async (
  applicantId: string,
  commentId: string
) => {
  const url = `${urls.GETSUBCONTRACTOR}/${applicantId}/toggle/commentId/${commentId}`;
  try {
    const callParams = await getCallParams("PATCH");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doDeleteComment = async (
  applicantId: string,
  commentId: string
) => {
  const url = `${urls.GETSUBCONTRACTOR}/${applicantId}/delete/commentId/${commentId}`;
  try {
    const callParams = await getCallParams("DELETE");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateComment = async (applicantId: string, comment: Comment) => {
  const url = `${urls.GETSUBCONTRACTOR}/${applicantId}/comment/${comment.id}`;
  try {
    const callParams = await getCallParams("PATCH", { comment: comment.text });
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
