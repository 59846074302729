import type { ReactNode } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { getApplicantIcon, getSettingsIcon } from "utils/DrawerIcons";
import strings from "global/constants/StringConstants";
import { store } from "utils/store";
import viewpaths from "global/constants/ViewPathConstants";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  accessTo: string[];
  accessToResource: string[];
  underDevelopment?: boolean;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  return [
    {
      items: [
        {
          title: strings.SUBCONTRACTOR,
          path: viewpaths.subcontractorViewPath,
          icon: (
            <SvgIcon fontSize="small">
              {getApplicantIcon("var(--nav-item-icon-color)")}
            </SvgIcon>
          ),
          activeIcon: (
            <SvgIcon fontSize="small">
              {getApplicantIcon("var(--nav-item-active-color)")}
            </SvgIcon>
          ),
          accessToResource: [],
          accessTo: ["all"],
        },

        // {
        //   title: strings.SETTINGS,
        //   path: viewpaths.settingsViewPath,
        //   icon: (
        //     <SvgIcon fontSize="small">
        //       {getSettingsIcon("var(--nav-item-icon-color)")}
        //     </SvgIcon>
        //   ),
        //   activeIcon: (
        //     <SvgIcon fontSize="small">
        //       {getSettingsIcon("var(--nav-item-active-color)")}
        //     </SvgIcon>
        //   ),
        //   accessToResource: [],
        //   accessTo: ["all"],
        // },
      ],
    },
  ];
};

export const useHasAccess = (path: string) => {
  const sections = useSections();
  const section = sections[0].items.find((sec) => path.includes(sec.path));
  if (section) {
    return permissionCheck(section);
  }
  return false;
};

export const useMakeUserAllowedSections = () => {
  const sections = useSections();
  const userSections = sections.map((section) => {
    const filteredSections = section.items.filter((item) => {
      return permissionCheck(item);
    });
    return {
      ...section,
      items: filteredSections,
    };
  });
  return userSections;
};

export const permissionCheck = (item: Item) => {
  if (item.accessTo.includes("all")) {
    // Permission will be checked in internal pages
    return true;
  }
  if (
    item.accessTo.includes("external") ||
    item.accessTo.includes("internal")
  ) {
    if (isAdmin()) {
      return true;
    }
  }
  return false;
};

export const useAuthRouteRedirection = () => {
  const routes = useMakeUserAllowedSections();
  if (routes[0].items.length > 0) {
    return routes[0].items[0].path;
  }
  return viewpaths.pageNotFoundViewPath;
};

export const isAdmin = () => {
  const role = store.getState().auth.role;
  if (role === strings.ROLE_ADMIN) {
    return true;
  }
  return false;
};
