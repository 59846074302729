import {
  Box,
  Button,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RouterLink } from "components/router-link";
import FallbackImage from "assets/images/Fallback.svg";

const Fallback = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const getBody = () => {
    return (
      <Stack
        sx={{
          backgroundColor: "white",
          height: "100vh",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          <Box
            alt="Not authorized"
            component="img"
            src={FallbackImage}
            sx={{
              height: "auto",
              maxWidth: "100%",
              width: 350,
            }}
          />
        </Box>
        <Typography align="center" variant={mdUp ? "h1" : "h4"}>
          Sorry about that! Something went wrong.
        </Typography>
        <Typography align="center" color="text.secondary" sx={{ mt: 2, mx: 2 }}>
          Try reloading the page. If the problem persists, please contact our
          support team.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Button variant="contained" component={RouterLink} href={"/"}>
            Back to Home
          </Button>
        </Box>
      </Stack>
    );
  };

  return getBody();
};

export default Fallback;
