import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Skeleton,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Subcontractor } from "../applicantTypes";
import { useLocation, useParams } from "react-router-dom";

import { handleErrorMessage } from "helpers/methods";
import history from "utils/history";
import BasicInfo from "screens/Applicants/ViewApplicant/components/BasicInfo";
import Comment from "screens/Applicants/ViewApplicant/components/Comment";
import { RouterLink } from "components/router-link";
import CenteredAuthenticatedLayout from "layouts/CenteredAuthenticated/CenteredAuthenticatedLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import title from "global/constants/TitleConstants";
import { useTitle } from "utils/UseTitle";
import { getRandomAvatarByGender } from "utils/Avatars";
import viewpaths from "global/constants/ViewPathConstants";
import { getApplicantById } from "../ApplicantService";
import Poc from "./components/Poc";
import WorkingLocation from "./components/WorkingLocation";
import XlProject from "./components/XlProject";

const tabs = [
  {
    label: "Information",
    value: "Information",
    key: "information",
  },
];

const fetchTab = (selectedTab: string = "", searchBasedOn: "label" | "key") => {
  const newSelection = tabs.find((tab) => tab[searchBasedOn] === selectedTab);
  return newSelection;
};

const ViewApplicant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const tab = new URLSearchParams(useLocation().search).get("tab");
  const tabToSelect = fetchTab(tab ?? "", "key");
  const [currentTab, setCurrentTab] = useState(
    tabToSelect?.label ?? tabs[0].label
  );
  const [candidateData, setCandidateData] = useState<Subcontractor>();
  useTitle(
    title.addPrefixToTitle(candidateData?.name ?? "", title.VIEW_SUBCONTRACTOR)
  );
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const { id } = useParams<{ id: string }>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>();

  useEffect(() => {
    const tabToSelect = fetchTab(tab ?? "", "key");
    setCurrentTab(tabToSelect?.label ?? tabs[0].value);
  }, [tab]);

  useEffect(() => {
    getCandidate();
  }, [id]);

  const getCandidate = async () => {
    try {
      setIsLoading(true);
      const response = await getApplicantById(id);
      setCandidateData(response);
      setProfileImage(getRandomAvatarByGender("default"));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
      history.push(viewpaths.subcontractorViewPath);
    }
  };

  const handleRowHover = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleEdit = (activeStep?: number, data?: any) => {
    history.push({
      pathname: `${viewpaths.editSubcontractorViewPath}/${id}`,
      state: {
        data: candidateData ? candidateData : data,
        activeStep: activeStep ? activeStep : 0,
        isEditState: true,
      },
    });
  };

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      const selectedTab = fetchTab(value, "label");
      history.replace(
        `${viewpaths.viewSubcontractorViewPath}/${id}?tab=${selectedTab!.key}`
      );
      setCurrentTab(value);
    },
    []
  );

  const getApplicantPageHeader = () => {
    return (
      <Stack direction="column" spacing={3}>
        <Link
          color="text.primary"
          href={viewpaths.subcontractorViewPath}
          sx={{
            alignItems: "center",
            display: "inline-flex",
          }}
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
          underline="hover"
          component={RouterLink}
        >
          <SvgIcon sx={{ mr: 1 }}>
            <ArrowBackIcon htmlColor="#5A5A5A" />
          </SvgIcon>
          <Typography variant="subtitle1" sx={{ color: "#5A5A5A" }}>
            Subcontractor
          </Typography>
        </Link>
        <Stack
          alignItems="flex-start"
          direction={{
            xs: "column",
            md: "row",
          }}
          justifyContent="space-between"
          spacing={4}
          mt={2}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="circular"
                height={64}
                width={64}
              />
            ) : (
              <Avatar
                src={profileImage}
                sx={{
                  height: 64,
                  width: 64,
                }}
              ></Avatar>
            )}
            <Stack direction="column" spacing={1}>
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  height={25}
                  width={190}
                />
              ) : (
                <Typography variant="h5">{candidateData?.name}</Typography>
              )}
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  height={25}
                  width={120}
                />
              ) : (
                <Typography variant="body2" color="#383838">
                  {candidateData?.field}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Button
              color="inherit"
              startIcon={<EditOutlinedIcon />}
              onClick={() => handleEdit()}
              variant="outlined"
              size="small"
            >
              Edit
            </Button>
          </Stack>
        </Stack>
        <Box>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={isLoading}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
      </Stack>
    );
  };

  const getApplicantInfoViewTab = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <BasicInfo candidateData={candidateData!} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <Poc
            candidateData={candidateData!}
            loader={isLoading}
            setLoader={setIsLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <WorkingLocation
            candidateData={candidateData!}
            loader={isLoading}
            setLoader={setIsLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <XlProject
            candidateData={candidateData!}
            loader={isLoading}
            setLoader={setIsLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Comment
            candidateData={candidateData!}
            loader={isLoading}
            setLoader={setIsLoading}
          />
        </Grid>
      </Grid>
    );
  };

  const getApplicantPageBody = () => {
    switch (currentTab) {
      case "Information":
        return getApplicantInfoViewTab();
      default: {
        return getApplicantInfoViewTab();
      }
    }
  };

  const getViewApplicantPage = () => {
    return (
      <>
        {getApplicantPageHeader()}
        <Box mt={2}>{getApplicantPageBody()}</Box>
      </>
    );
  };

  return (
    <CenteredAuthenticatedLayout>
      {getViewApplicantPage()}
    </CenteredAuthenticatedLayout>
  );
};

export default ViewApplicant;
