import type { Theme } from "@mui/material/styles/createTheme";
import { styled, useMediaQuery } from "@mui/material";
import { useMobileNav } from "hooks/use-mobile-nav";
import { useMakeUserAllowedSections } from "../Config";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import MobileNav from "./components/MobileNav";

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface CustomProps {
  children: any;
}

const DrawerLayout = (props: CustomProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const sections = useMakeUserAllowedSections();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {lgUp && <SideNav sections={sections} />}
      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{props.children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

export default DrawerLayout;
