import React from "react";
import type { Theme } from "@mui/material/styles/createTheme";
import { styled, useMediaQuery } from "@mui/material";
import { useMobileNav } from "hooks/use-mobile-nav";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import MobileNav from "./components/MobileNav";
import { useMakeUserAllowedSections, useSections } from "layouts/Config";

const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
});

const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

interface CustomProps {
  children: any;
}

const TopNavLayout = (props: CustomProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const sections = useMakeUserAllowedSections();

  return (
    <>
      <TopNav onMobileNav={mobileNav.handleOpen} />
      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <HorizontalLayoutRoot>
        <HorizontalLayoutContainer>{props.children}</HorizontalLayoutContainer>
      </HorizontalLayoutRoot>
    </>
  );
};

export default TopNavLayout;
