import { Box, InputLabel, SxProps, TextField } from "@mui/material";
import customInputStyles from "global/components/CustomInput/CustomInput.styles";

interface CustomProps {
  autoFocus?: boolean;
  label?: any;
  placeHolder?: string;
  value?: string | number;
  onChange?: any;
  type?: string;
  name?: any;
  select?: boolean;
  onKeyPress?: any;
  InputProps?: any;
  error?: any;
  required?: boolean;
  InputLabelProps?: any;
  id?: string;
  sx?: SxProps;
  propsToInputElement?: any;
  disabled?: boolean;
  onBlur?: Function;
  onClick?: Function;
  helperText?: string;
  inputType?: string;
  onPaste?:Function
}

const CustomInput = (props: CustomProps) => {
  const classes = customInputStyles;
  const { error = null } = props;

  return (
    <Box>
      {props.label && (
        <Box mb={1}>
          <InputLabel required={props.required}>{props.label}</InputLabel>
        </Box>
      )}
      <TextField
        autoFocus={props.autoFocus ?? false}
        sx={props.sx ? props.sx : classes.textField}
        fullWidth
        variant="outlined"
        id={props.id}
        placeholder={props.placeHolder}
        type={props.type}
        name={props.name}
        select={props.select}
        value={props.value}
        InputProps={props.InputProps}
        inputProps={props.propsToInputElement}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        onPaste={props.onPaste}
        required={props.required}
        onBlur={props.onBlur}
        onClick={props?.onClick}
        {...(error && { error: true, helperText: error })}
        disabled={props.disabled}
        helperText={props.helperText}
      />
    </Box>
  );
};

export default CustomInput;
