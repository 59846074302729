import TopNavItem from "./TopNavItem";
import { Stack, useMediaQuery, Theme, Box } from "@mui/material";
import { Item, permissionCheck } from "layouts/Config";

interface CustomProps {
  items?: Item[];
  pathname?: string | null;
  subheader?: string;
}

const renderItems = ({
  depth = 0,
  items,
  pathname,
}: {
  depth?: number;
  items: Item[];
  pathname?: string | null;
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
      }),
    []
  );

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  pathname?: string | null;
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path!) : false;
  const exactMatch = checkPath ? pathname === item.path : false;
  const underDevelopment = item.underDevelopment ?? false;
  const hasAccess = permissionCheck(item);

  if (underDevelopment) {
    return acc;
  }

  if (!hasAccess) {
    return acc;
  }

  if (item.items) {
    acc.push(
      <TopNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        activeIcon={item.activeIcon}
        items={item.items}
        key={item.title}
        label={item.label}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </Stack>
      </TopNavItem>
    );
  } else {
    acc.push(
      <TopNavItem
        active={exactMatch || partialMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        activeIcon={item.activeIcon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const TopNavSection = (props: CustomProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <Stack
      component="ul"
      direction="row"
      spacing={1}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      {props.subheader && (
        <Box
          component="li"
          sx={{
            color: "var(--nav-section-title-color)",
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: "uppercase",
          }}
        >
          {props.subheader}
        </Box>
      )}
      {renderItems({ items: props.items ?? [], pathname: props.pathname })}
    </Stack>
  );
};

export default TopNavSection;
