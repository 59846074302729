import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RouterLink } from "components/router-link";
import MenuIcon from "@mui/icons-material/Menu";
import TopNavSection from "./TopNavSection";
import { usePathname } from "hooks/use-pathname";
import { useTheme } from "@mui/material/styles";
import Logo from "assets/images/PrimaryLogo.svg";
import { useMakeUserAllowedSections, useSections } from "layouts/Config";
import AccountPopover from "./AccountPopover";

const useCssVars = (): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.background.default,
        "--nav-color": theme.palette.neutral[100],
        "--nav-divider-color": theme.palette.neutral[800],
        "--nav-border-color": theme.palette.neutral[700],
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.text.primary,
        "--nav-item-disabled-color": theme.palette.neutral[600],
        "--nav-item-icon-color": theme.palette.neutral[500],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[700],
        "--nav-item-chevron-color": theme.palette.neutral[700],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    } else {
      return {
        "--nav-bg": "#242A3A",
        "--nav-color": "#FFFFFF",
        "--nav-divider-color": "#343434",
        "--nav-border-color": theme.palette.neutral[100],
        "--nav-logo-border": "#242A3A",
        "--nav-section-title-color": theme.palette.neutral[400],
        "--nav-item-color": "#5E6979",
        "--nav-item-hover-bg": "#474C5B",
        "--nav-item-active-bg": "#242A3A",
        "--nav-item-active-color": "#FFFFFF",
        "--nav-item-disabled-color": theme.palette.neutral[400],
        "--nav-item-icon-color": "#757D90",
        "--nav-item-icon-active-color": "#FFFFFF",
        "--nav-item-icon-disabled-color": theme.palette.neutral[400],
        "--nav-item-chevron-color": theme.palette.neutral[400],
        "--nav-scrollbar-color": theme.palette.neutral[900],
      };
    }
  }, [theme]);
};

interface CustomProps {
  onMobileNav: () => void;
}

const TopNav = (props: CustomProps) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const cssVars = useCssVars();
  const sections = useMakeUserAllowedSections();
  const pathname = usePathname();
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const container = document.getElementById("nav-items");
    if (container && container.scrollWidth > container.clientWidth) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, [mdUp]);

  return (
    <Box
      component="header"
      sx={{
        ...cssVars,
        backgroundColor: mdUp ? "var(--nav-bg)" : "transparent",
        borderBottomColor: "var(--nav-border-color)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        color: "var(--nav-color)",
        left: 0,
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 1,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!mdUp && (
            <IconButton onClick={props.onMobileNav}>
              <MenuIcon />
            </IconButton>
          )}
          {mdUp && (
            <Box
              component={RouterLink}
              sx={{
                borderColor: "var(--nav-logo-border)",
                borderRadius: 1,
                borderStyle: "solid",
                borderWidth: 1,
                display: "inline-flex",
                height: 40,
                p: "4px",
                width: 40,
              }}
            >
              <img
                src={Logo}
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "#FFFFFF",
                }}
                alt="logo"
              />
            </Box>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <AccountPopover />
        </Stack>
      </Stack>
      {mdUp && (
        <Box
          sx={{
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "var(--nav-divider-color)",
          }}
        >
          <Stack
            id="nav-items"
            alignItems="center"
            component="nav"
            direction="row"
            spacing={1}
            sx={{
              px: 2,
              pt: 1.5,
              pb: hasScrollbar ? 0 : 1.5,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "15px",
              },
              "&::-webkit-scrollbar-thumb": {
                border: "5px solid rgba(0, 0, 0, 0)",
                backgroundClip: "padding-box",
                borderRadius: " 9999px",
                backgroundColor: "#242A3A",
              },
              "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#c3c4c775",
                },
              },
            }}
          >
            {sections.map((section: any, index: any) => (
              <TopNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default TopNav;
