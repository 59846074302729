import { Card, CardHeader, List, Stack } from "@mui/material";
import { Subcontractor } from "screens/Applicants/applicantTypes";
import { PropertyListItem } from "components/property-list-item";
import { formattedPhoneNumber, isTruthy } from "helpers/methods";

interface CustomProps {
  candidateData: Subcontractor;
  isLoading: boolean;
}

const BasicInfo = (props: CustomProps) => {
  const blurSocialSecurityNumber = (blurSSN?: string) => {
    if (blurSSN!.length >= 9) {
      blurSSN = blurSSN!.replace(/^(\d{3})(\d{2})(\d{4})/, "XXX-XX-$3");
    }
    return blurSSN;
  };

  const formatAddress = (address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    pinCode: string;
  }) => {
    const addressComponents = [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.country,
      address.pinCode,
    ];
    return addressComponents
      .filter((component) => isTruthy(component))
      .join(", ");
  };

  return (
    <Card>
      <CardHeader title="Basic Details" />
      <Stack spacing={2} direction="row">
        <PropertyListItem
          divider
          label="Bid Email"
          value={
            isTruthy(props.candidateData?.email)
              ? props.candidateData?.email
              : "-"
          }
          isLoading={props.isLoading}
        />

        <PropertyListItem
          divider
          label="Office Number"
          value={
            isTruthy(props.candidateData?.phone?.value)
              ? `+${formattedPhoneNumber(
                  `${props.candidateData?.phone?.countryCode} ${props.candidateData?.phone?.value}`
                )}`
              : "-"
          }
          isLoading={props.isLoading}
        />
        <PropertyListItem
          label="Address"
          value={
            props.candidateData?.address ? props.candidateData?.address : "-"
          }
          isLoading={props.isLoading}
        />
      </Stack>
    </Card>
  );
};

export default BasicInfo;
