import { Route, RouteProps } from "react-router-dom";
import history from "utils/history";
import viewpaths from "global/constants/ViewPathConstants";

interface CustomProps extends RouteProps {
  component?: any;
  isLoggedIn: boolean;
}

const PrivateRoute = (props: CustomProps) => {
  const { component: Component, isLoggedIn, path, ...rest } = props;

  if (!isLoggedIn) {
    history.push(viewpaths.rootViewPath);
    return null;
  }
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

export default PrivateRoute;
