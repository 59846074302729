import urls from "global/constants/UrlConstants";
import { makeCall, getNoAuthCallParams } from "utils/service";

export async function login(email: string, password: string, token: string) {
  const body = {
    email: email,
    password: password,
  };
  try {
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.LOGIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
