import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { RootState } from "utils/store";

export interface PreferenceState {
  layout: string;
  theme: string;
}

const initialState: PreferenceState = {
  layout: "",
  theme: "",
};

export const preferenceSlice: Slice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    updatePreference: (
      state,
      action: PayloadAction<{
        layout: string;
        theme: string;
      }>
    ) => {
      state.layout = action.payload.layout;
      state.theme = action.payload.theme;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    updateLayout: (state, action: PayloadAction<string>) => {
      state.layout = action.payload;
    },
  },
});

export const { updatePreference, updateTheme, updateLayout } =
  preferenceSlice.actions;

export const selectLayout = (state: RootState) => state.preference.layout;
export const selectTheme = (state: RootState) => state.preference.theme;

export default preferenceSlice.reducer;
