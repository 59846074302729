import type { FC } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import type { ListItemProps } from "@mui/material/ListItem";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Skeleton, Stack } from "@mui/material";

type Direction = "horizontal" | "vertical";

interface PropertyListItemProps extends ListItemProps {
  align?: Direction;
  label: string | JSX.Element;
  value?: string;
  childrenAlign?: Direction;
  isLoading?: boolean;
}

export const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  const {
    align = "vertical",
    children,
    disableGutters,
    value,
    childrenAlign: childrenAlign,
    label,
    ...other
  } = props;

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 3,
        py: 1.5,
      }}
      {...other}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={{ minWidth: align === "vertical" ? "inherit" : 180 }}
            variant="subtitle2"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              mt: align === "vertical" ? 0.5 : 0,
            }}
          >
            <Stack
              direction={childrenAlign === "horizontal" ? "row" : "column"}
              sx={
                childrenAlign === "horizontal"
                  ? {
                      display: "flex",
                      alignItems: "center",
                    }
                  : {}
              }
            >
              {props.isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  height={25}
                  width={100}
                />
              ) : (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {value}
                  </Typography>
                  {children && (
                    <Box
                      sx={{
                        marginLeft: childrenAlign === "horizontal" ? 1 : 0,
                      }}
                    >
                      {children}
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </Box>
        }
        sx={{
          display: "flex",
          flexDirection: align === "vertical" ? "column" : "row",
          my: 0,
        }}
      />
    </ListItem>
  );
};

PropertyListItem.propTypes = {
  align: PropTypes.oneOf<Direction>(["horizontal", "vertical"]),
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
