import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { RootState } from "utils/store";
import { Resources } from "models/interfaces";

export interface Primary {
  id: string;
  email: string;
  name: string;
  officePhone: string;
  lastLogin: any;
}

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  refreshToken: string;
  loading: boolean;
  userName: string;
  userId: string;
  email: string;
  userRole: string;
  resources: Resources[];
  profileImage: string;
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  refreshToken: "",
  loading: false,
  userName: "",
  email: "",
  userId: "",
  userRole: "",
  resources: [],
  profileImage: "",
};

export const authSlice: Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        accessToken: string;
        refreshToken: string;
        userName: string;
        email: string;
        userRole: string;
        resources: Resources[];
        profileImage: string;
        userId: string;
      }>
    ) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.userRole = action.payload.userRole;
      state.resources = action.payload.resources;
      state.profileImage = action.payload.profileImage;
      state.userId = action.payload.userId;
    },
    addLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logOutAction: (state) => {
      state.authenticated = false;
      state.loading = false;
      state.accessToken = "";
      state.userId = "";
      state.userName = "";
      state.resources = [];
      state.profileImage = "";
      state.userId = "";
    },
    updateAccessToken: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
    },
    makeAppDrawerHide: (state, action: PayloadAction<boolean>) => {
      state.hideAppDrawer = action.payload;
    },
    updateUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updateProfileImage: (state, action: PayloadAction<string>) => {
      state.profileImage = action.payload;
    },
  },
});

export const {
  loginAction,
  logOutAction,
  addLoading,
  makeAppDrawerHide,
  updateUserName,
  updateEmail,
  updateAccessToken,
  updateProfileImage,
} = authSlice.actions;

export const selectAuthenticated = (state: RootState) =>
  state.auth.authenticated;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectUserRole = (state: RootState) => state.auth.userRole;
export const selectId = (state: RootState) => state.auth.userId;
export const selectResources = (state: RootState) => state.auth.resources;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectUserName = (state: RootState) => state.auth.userName;
export const selectEmail = (state: RootState) => state.auth.email;
export const selectProfileImage = (state: RootState) => state.auth.profileImage;

export default authSlice.reducer;
