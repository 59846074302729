import { Popover } from "@mui/material";
import { ReactNode } from "react";

interface CustomProps {
  anchorRef: any;
  open: boolean;
  handleClose: Function;
  children?: ReactNode;
  width?: string;
  classes?: any;
}

const CustomPopper = (props: CustomProps) => {
  const getPopper = () => {
    return (
      <Popover
        anchorEl={props.anchorRef}
        // disableScrollLock
        onClose={() => props.handleClose()}
        open={props.open}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        // transformOrigin={{
        //   horizontal: 'center',
        //   vertical: 'top',
        // }}
        PaperProps={{
          elevation: 8,
          sx: {
            ml: 1,
            mt: 1.5,
            p: 1,
            width: props.width,
          },
        }}
        sx={
          props.classes
            ? props.classes
            : { backgroundColor: "rgba(17, 25, 39, 0.75)" }
        } // @TODO
      >
        {props.children}
      </Popover>
    );
  };

  return getPopper();
};

export default CustomPopper;
