const applicantsStyles = {
  labelText: {
    color: "#ADADAD",
    wordBreak: "break-word",
  },
  valueText: {
    color: "#383838",
    wordBreak: "break-word",
  },
  chip: {
    maxHeight: "28px",
    ml: 1,
    mt: 1,
    borderRadius: "10px",
    "&.MuiChip-root ": {
      backgroundColor: "#DAEFC2",
      opacity: 1,
    },
    "&.MuiChip-label ": {
      // ...regularFont,
      // fontSize: getRelativeFontSize(1),
      color: "black",
    },
    "&.css-15ka1ix-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon": {
      color: "black",
    },
  },
  stateChip: {
    "&.MuiChip-root ": {
      backgroundColor: "#c2e6ef",
      opacity: 1,
    },
  },
  cityChip: {
    "&.MuiChip-root ": {
      backgroundColor: "#dfc2ef",
      opacity: 1,
    },
  },
  specialityChip: {
    "&.MuiChip-root ": {
      backgroundColor: "#efddc2",
      opacity: 1,
    },
  },
  nameField: {
    // ...boldFont,
    // fontSize: getRelativeFontSize(-1),
    color: "black",
    textTransform: "capitalize",
    "& .MuiFormLabel-asterisk": {
      color: "red",
    },
  },
  filterChip: {
    maxHeight: "28px",
    // ml: 1,
    // mt: 1,
    borderRadius: "10px",
    "&.MuiChip-root ": {
      backgroundColor: "#DAEFC2",
      opacity: 1,
    },
    "&.MuiChip-label ": {
      // ...regularFont,
      // fontSize: getRelativeFontSize(1),
      color: "black",
    },
    "&.css-15ka1ix-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon": {
      color: "black",
    },
  },
};

export default applicantsStyles;
