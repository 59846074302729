import React from "react";
import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useMobileNav } from "hooks/use-mobile-nav";
import { alpha } from "@mui/system/colorManipulator";
import TopNavSection from "./TopNavSection";
import MenuIcon from "@mui/icons-material/Menu";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface CustomProps {
  onMobileNavOpen: () => void;
  sections?: any;
}

const TopNav = (props: CustomProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={props.onMobileNavOpen}>
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <TopNavSection />
        </Stack>
      </Stack>
    </Box>
  );
};

export default TopNav;
