import { Box, Container } from "@mui/material";

interface CustomProps {
  children: any;
}

const CenteredAuthenticatedLayout = (props: CustomProps) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        mt: 2,
        pb: 6,
        // backgroundColor: mode === "dark" ? "#111927" : "#FFF",
        // backgroundColor: "#FFF",
      }}
    >
      <Container maxWidth={"xl"}>
        {/* <Grid
          container
          spacing={{
            xs: 3,
            lg: 4,
          }}
        > */}
        {props.children}
        {/* </Grid> */}
      </Container>
    </Box>
  );
};

export default CenteredAuthenticatedLayout;
