import {
  Box,
  Button,
  Card,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import viewCandidateStyles from "../ViewApplicant.styles";
import {
  getAddComments,
  getApplicantById,
  doPinUnpinComment,
  updateComment,
  doDeleteComment,
} from "../../ApplicantService";
import { Subcontractor } from "../../applicantTypes";
import {
  formatDateWithTimeInLongDateFormat,
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "helpers/methods";
import Edit from "assets/icons1/EditDetailsIcon.svg";
import Delete from "assets/icons1/DeleteIcon.svg";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";
import CustomTable from "global/components/CustomTable/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import DeleteCommentModal from "./DeleteCommentModal";
import AddEditCommentModal from "./AddEditCommentModal";

interface CustomProps {
  candidateData: Subcontractor;
  loader: boolean;
  setLoader: Function;
}

const commentTableHeaders = [
  // {
  //   name: "",
  //   field: "pinStatus",
  //   align: "center",
  // },
  {
    name: "Comment By",
    field: "commentBy",
    align: "left",
  },
  {
    name: "Comment",
    field: "comment",
    align: "left",
  },
  {
    name: "Action",
    field: "action",
    align: "center",
  },
];

const Comment = (props: CustomProps) => {
  const [commentOrder, setCommentOrder] = useState<number>();
  const [commentId, setCommentId] = useState("");
  const [newComment, setNewComment] = useState("");
  const [edit, setEdit] = useState<boolean>(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [selectedComment, setSelectedComment] = useState<any>();
  const [formFields, setFormFields] = useState<any>({
    value: props.candidateData?.comment ?? [],
    error: "",
  });
  const classes = viewCandidateStyles;
  const isLoggedInUser = useAppSelector(selectEmail);
  const [pinUnpinComment, setPinUnpinComment] = useState<boolean>(false);
  const [deleteComment, setDeleteComment] = useState<boolean>(false);
  const [addEditComment, setAddEditComment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    isTruthy(props.candidateData!) &&
      setFormFields({
        value: props.candidateData?.comment ?? formFields.value,
        error: "",
      });
  }, [props.candidateData!]);

  const handleRowHover = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    setHoveredRowIndex(null);
  };

  const getDeleteIcon = (row: any) => {
    return (
      <Box>
        <img
          id="deleteIcon"
          src={Delete}
          width="25px"
          height="25px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDeleteComment(true);
            setCommentId(row.id ?? "");
          }}
          alt="Delete Comment"
        />
      </Box>
    );
  };

  const getEditIcon = (row: any) => {
    return (
      <Box>
        <img
          id="editCommentImg"
          src={Edit}
          width="25px"
          height="25px"
          onClick={() => {
            setAddEditComment(true);
            setEdit(true);
            setNewComment(row.text);
            setSelectedComment(row);
          }}
          style={{ cursor: "pointer" }}
          alt="Edit Comment"
        />
      </Box>
    );
  };

  const convertCommentsToTable = (data: any) => {
    data = data.filter((comment: any) => comment.status === "active");
    return data.map((comment: any, index: number) => {
      return {
        // pinStatus: (
        //   <img
        //     src={comment.order === 1 ? PinComment : UnpinComment}
        //     width="25px"
        //     height="25px"
        //     style={{ cursor: "pointer" }}
        //     onClick={() => {
        //       setPinUnpinComment(true);
        //       setCommentId(comment.id ?? "");
        //       setCommentOrder(comment.order);
        //     }}
        //     alt="Pin Comment"
        //   />
        // ),
        commentBy: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.by}
            </Typography>
            <Typography variant="body2">
              {formatDateWithTimeInLongDateFormat(comment.on)}
            </Typography>
          </Box>
        ),
        comment: (
          <Stack direction="row" alignItems="center">
            {hoveredRowIndex === index ? (
              <Tooltip
                sx={{ background: "green" }}
                title={
                  <Box sx={{ maxHeight: "300px", overflowY: "scroll" }}>
                    <Typography
                      sx={{
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                      }}
                      variant="body2"
                    >
                      {comment.text}
                    </Typography>
                  </Box>
                }
                placement="bottom-start"
              >
                <Typography
                  sx={{
                    ...classes.comment,
                    cursor: "pointer",
                  }}
                >
                  {comment.text}
                </Typography>
              </Tooltip>
            ) : (
              <Typography sx={classes.comment} variant="body1">
                {comment.text}
              </Typography>
            )}
          </Stack>
        ),
        action: (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {getEditIcon(comment)}
            {getDeleteIcon(comment)}
          </Stack>
        ),
      };
    });
  };

  const handlePinUnpinComment = async () => {
    try {
      setIsLoading(true);
      const response = await doPinUnpinComment(
        props.candidateData._id,
        commentId
      );
      setPinUnpinComment(false);
      const data = await getApplicantById(props.candidateData?._id ?? "");
      openSuccessNotification(response.value);
      setFormFields({
        value: data?.comment,
        error: "",
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleDeleteComment = async () => {
    try {
      setIsLoading(true);
      const response = await doDeleteComment(
        props.candidateData._id,
        commentId
      );
      setDeleteComment(false);
      const data = await getApplicantById(props.candidateData?._id ?? "");
      openSuccessNotification(response.value);
      setFormFields({
        value: data?.comment,
        error: "",
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleAddUpdateComment = async () => {
    try {
      setIsLoading(true);
      if (newComment.trim() !== "") {
        const response = edit
          ? await updateComment(props.candidateData._id, {
              ...selectedComment,
              text: newComment,
            })
          : await getAddComments(newComment, props.candidateData?._id);
        setAddEditComment(false);
        setNewComment("");
        const data = await getApplicantById(props.candidateData?._id ?? "");
        openSuccessNotification(response.value);
        setFormFields({
          value: data?.comment,
          error: "",
        });
      } else {
        setFormFields({
          ...formFields,
          error: "Please enter a valid comment",
        });
        setNewComment("");
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getActionModals = () => {
    return (
      <>
        {/* <PinUnpinCommentModal
          handleDialogClose={() => setPinUnpinComment(false)}
          isVisible={pinUnpinComment}
          handleOnChange={() => handlePinUnpinComment()}
          commentOrder={commentOrder}
          isLoading={isLoading}
        /> */}
        <DeleteCommentModal
          handleDialogClose={() => setDeleteComment(false)}
          isVisible={deleteComment}
          handleOnChange={() => handleDeleteComment()}
          isLoading={isLoading}
        />
        <AddEditCommentModal
          handleDialogClose={() => setAddEditComment(false)}
          isVisible={addEditComment}
          handleOnChange={() => handleAddUpdateComment()}
          edit={edit}
          newComment={newComment}
          setNewComment={setNewComment}
          formFields={formFields}
          setFormFields={setFormFields}
          isLoading={isLoading}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Additional Note"
          action={
            <Button
              variant="contained"
              size="small"
              startIcon={
                <Stack>
                  <AddIcon />
                </Stack>
              }
              onClick={() => {
                setAddEditComment(true);
                setEdit(false);
                setNewComment("");
              }}
            >
              Add
            </Button>
          }
        />
        <Box mt={1}>
          <CustomTable
            headers={commentTableHeaders}
            rows={convertCommentsToTable(formFields.value)}
            showPagination={false}
            onRowHover={handleRowHover}
            onRowLeave={handleRowLeave}
            isLoading={props.loader}
          />
        </Box>
        {getActionModals()}
      </Card>
    </>
  );
};

export default Comment;
