import Backdrop from "@mui/material/Backdrop";
// import Loader from "assets/images/Loader.gif";
import { Box, CircularProgress } from "@mui/material";

interface CustomLoaderProps {
  isLoading?: boolean;
}

const CustomLoader = (props: CustomLoaderProps) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme: any) => theme.zIndex.drawer + 101,
        backgroundColor: "rgb(255 243 243 / 50%) !important",
      }}
      open={props.isLoading!}
    >
      {/* <Box
        sx={{
          width: "65px",
          height: "65px",
          background: "#6842EF",
          borderRadius: "15px",
        }}
      >
        <img src={Loader} width="60px" />
      </Box> */}
      <CircularProgress sx={{ color: "black" }} />
    </Backdrop>
  );
};

export default CustomLoader;
