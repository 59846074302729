class TitleConstants {
  App = "XL Construction";
  Separator = " | ";

  FormattedTitleEnd = this.Separator + this.App;

  LOGIN = "Login" + this.FormattedTitleEnd;
  FORGOT_PASSWORD = "Forgot Password" + this.FormattedTitleEnd;

  SUBCONTRACTOR = "Subcontractor" + this.FormattedTitleEnd;
  VIEW_SUBCONTRACTOR = "Subcontractor" + this.FormattedTitleEnd;
  ADD_SUBCONTRACTOR = "Add Subcontractor" + this.FormattedTitleEnd;
  EDIT_SUBCONTRACTOR = "Edit Subcontractor" + this.FormattedTitleEnd;

  SETTING = "Settings" + this.FormattedTitleEnd;
  GENERAL = "General" + this.FormattedTitleEnd;
  USERS = "Users" + this.FormattedTitleEnd;
  ROLE_MANAGEMENT = "Role Management" + this.FormattedTitleEnd;
  SECURITY = "Security" + this.FormattedTitleEnd;
  PREFERENCES = "Preferences" + this.FormattedTitleEnd;
  ACCOUNT = "Account" + this.FormattedTitleEnd;

  addPrefixToTitle = (prefix: string, suffix: string) => {
    if (!prefix) {
      return suffix;
    }
    return prefix + this.Separator + suffix;
  };
}

let title = new TitleConstants();
export default title;
