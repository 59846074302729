import { Box } from "@mui/material";
import strings from "global/constants/StringConstants";
import { globalEmitter } from "utils/emitter";
import { Error, FormattedResources, Resources } from "models/interfaces";
import { logOutAction } from "redux/authSlice";
import { store } from "utils/store";
import history from "utils/history";
import MomentHelpers from "helpers/MomentHelpers";
import moment from "moment-timezone";
import { PhoneNumberUtil } from "google-libphonenumber";
import viewpaths from "global/constants/ViewPathConstants";

const methodsContext = this;

export const isTruthy = (value: any, shouldCheckByType: boolean = true) => {
  const validatedByType = shouldCheckByType
    ? customValidatorByType(value)
    : true;

  if (value !== null && value !== undefined && validatedByType) {
    return true;
  }
  return false;
};

const customValidatorByType = (value: any) => {
  if (value !== undefined && value !== null) {
    const type = typeof value;
    switch (type) {
      case "string":
        return value.trim() !== "";
      case "object":
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      default:
        return true;
    }
  }
};

export const compareStrings = (string1: string, string2: string) => {
  if (!(isTruthy(string1) || isTruthy(string2))) {
    return true;
  } else {
    if (string1 && string2) {
      if (string1.toLowerCase() === string2.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const getGreetings = () => {
  const date = new Date();
  let hours = date.getHours();
  let status =
    hours < 12
      ? "Good Morning"
      : hours <= 18 && hours >= 12
      ? "Good Afternoon"
      : "Good Evening";

  return status;
};

export const openInfoNotification = (message: any, title: string = "Info") => {
  globalEmitter.emit(strings.notification, {
    type: strings.info,
    message: message,
    title: title,
  });
};

export const openSuccessNotification = (
  message: any,
  title: string = "Success"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.success,
    message: message,
    title: title,
  });
};

export const openWarningNotification = (
  message: any,
  title: string = "Warning"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.warning,
    message: message,
    title: title,
  });
};

export const handleErrorMessage = (error: Error) => {
  if (error.statusCode) {
    switch (error.statusCode) {
      case 400:
        return openErrorNotification("Please check your request and try again");
      case 401:
        store.dispatch(logOutAction({}));
        history.push(viewpaths.landingViewPath);
        return null;
      case 403:
        return openErrorNotification(
          "You are not authorized to access this resource."
        );
      case 404:
        return openErrorNotification(
          "The requested resource could not be found."
        );
      case 429:
        return openErrorNotification(
          "Our servers are currently experiencing a high volume of requests. Please try again in a few minutes."
        );
      case 500:
        return openErrorNotification(
          "There was an error on the server side. Please try again later."
        );
      case 406:
        return openErrorNotification(error.message);
      default:
        return openErrorNotification(
          "There was an error on the server side. Please try again later."
        );
    }
  } else if (
    error.errorMessage === "Failed to fetch" &&
    error.name === "TypeError"
  ) {
    return openErrorNotification(
      "This is a critical error that requires immediate attention from the support team. Please contact them."
    );
  }
};

export const openErrorNotification = (
  message: any,
  title: string = "Error"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.error,
    message: message,
    title: title,
  });
};

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (...args: any) {
    const context = methodsContext;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceEventHandler(func: Function, wait: number) {
  const debounced = debounce(func, wait);
  return function (event: any) {
    event.persist();
    return debounced(event);
  };
}

export const getFormattedNumbers = (value: string) => {
  const matches = value.match(/\d+/g);
  let number = "";
  if (matches !== null) {
    matches.forEach((match) => {
      number += match;
    });
  }
  if (number.length === 10) {
    value = number.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return { number: number, maskedNumber: value };
};

export const getFileExtension = (fileName: string) => {
  const ext = /^.+\.([^.]+)$/.exec(fileName);
  return ext === null ? "" : ext[1];
};

export const validatePhoneNumber = (mobile: string) => {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(mobile);
};

export const isPhoneValid = (phone: string) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error: any) {
    return false;
  }
};

export const tableRowRightAlign = (value: any) => {
  return (
    <Box component="span" sx={{ textAlign: "right", display: "block" }}>
      {value}
    </Box>
  );
};

const doesResourcesExistInLocalStorage = (resources: Resources[]) => {
  if (!isTruthy(resources)) {
    store.dispatch(logOutAction({}));
    history.push(viewpaths.landingViewPath);
    return false;
  }
  return true;
};

export const convertResourceToObjectFormat = (resources: Resources[]) => {
  if (!doesResourcesExistInLocalStorage(resources)) {
    return {} as FormattedResources;
  }
  let returnObject = {} as { [key: string]: string[] };
  resources.forEach((resource) => {
    returnObject[resource.path] = resource.permissions;
  });
  return returnObject;
};

export const convertESTtoUserLocalTime = (inputTime: string) => {
  const userTimeZone = MomentHelpers.guessTheTimeZone();
  const inputTimezone = "America/New_York";
  const inputTimeFormat = "MM-DD-YYYY HH:mm:ss";
  const outputTimeFormat = "hh:mm a";
  return moment
    .tz(inputTime, inputTimeFormat, inputTimezone)
    .tz(userTimeZone)
    .format(inputTimeFormat);
};

export function isValidEmail(email: string = "") {
  return strings.EmailRegex.test(email);
}

export function isPasswordStrong(password: string = "") {
  return strings.PasswordRegex.test(password);
}

export function isValidOTP(otp: string = "", digits: number = 6) {
  return otp.length === digits;
}

export function checkPasswordStrength(password: string = "") {
  let percentage = 0;
  // Check minimum length
  if (password.length >= 8) {
    percentage += 25;
  }
  // Check for at least one lowercase letter
  if (/[a-z]/.test(password)) {
    percentage += 25;
  }
  // Check for at least one number
  if (/\d/.test(password)) {
    percentage += 25;
  }
  // Check for at least one uppercase letter
  if (/[A-Z]/.test(password)) {
    percentage += 25;
  }
  // Determine strength based on percentage
  const strength = percentage === 100 ? "Strong" : "Weak";
  return { strength, percentage };
}

export function formatDateInLongDateFormat(date: Date | string) {
  return moment(date).format("LL");
}

export function formatDateWithTimeInLongDateFormat(date: Date | string) {
  return moment(date).format("MMMM D, YYYY");
}

export const convertPriceToDollarFormat = (value: number) => {
  return `$${value.toFixed(2)}`;
};

export const getPhoneNumberWithCountryCode = (number: string) => {
  const countryCode = number.substring(1, number.indexOf(" "));
  const phoneNumber = number
    .substring(number.indexOf(" "))
    .split("")
    .filter(
      (char: string) =>
        char !== " " && char !== "(" && char !== ")" && char !== "-"
    )
    .join("");

  const formattedObject = {
    countryCode: parseInt(countryCode),
    value: parseInt(phoneNumber),
  };

  return formattedObject;
};

export const formattedPhoneNumber = (number: string) => {
  const formattedNumber: string = number.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedNumber;
};

export const addHours = (timeObject: any, field?: string): string => {
  let addedMinutes = 0;

  if (!field) {
    Object.keys(timeObject).forEach((key) => {
      const fieldValue = timeObject[key];
      const [hours, minutes] = fieldValue.split(":").map(Number);
      addedMinutes += hours * 60 + minutes;
    });
  } else {
    timeObject.forEach((currObj: any) => {
      const fieldValue = currObj[field];
      const [hours, minutes] = fieldValue.split(":").map(Number);
      addedMinutes += hours * 60 + minutes;
    });
  }

  const resultHours = Math.floor(addedMinutes / 60);
  const resultMinutes = addedMinutes % 60;
  return `${resultHours.toString().padStart(2, "0")}:${resultMinutes
    .toString()
    .padStart(2, "0")}`;
};

export const stringToFile = (url: string, fileName: string): File => {
  const byteString = atob(url.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ab], fileName, { type: "image/png" });
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const calculateRelativeTime = (inputTime: string): string => {
  const userTimeZone = moment.tz.guess();
  const providedDate = moment.tz(inputTime, "America/New_York"); // Assuming input time is in EST
  const localTime = providedDate.clone().tz(userTimeZone);
  return localTime.fromNow();
};

export const formatToCurrency = (value: number, currency: string = "USD") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(value);
};

export const trimValue = (value: string) => {
  return value.trim();
};

export const formatAddress = (address: {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
}) => {
  const addressComponents = [
    address.line1,
    address.line2,
    address.city,
    address.state,
    address.country,
    address.pinCode,
  ];
  return addressComponents
    .filter((component) => isTruthy(component))
    .join(", ");
};

export const blockInvalidChar = (event: any) =>
  ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
