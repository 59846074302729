const addApplicantStyles = {
  datePicker: {
    "& .MuiInputBase-input": { padding: "13px 13px" },
  },
  placeholderText: {
    color: "#bfbfbf",
  },
  select: {
    "& .MuiInputBase-input": {
      padding: "13px 13px",
    },
    ".MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator": {
      color: "green",
    },
  },
  errorStyling: {
    paddingLeft: "14px",
  },
  centerItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeBtn: {
    color: "#505050",
    borderRadius: "50%",
    width: "fit-content",
    minWidth: "0px",
    height: "inherit",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    p: 1,
  },
  viewDocumentText: {
    color: "#74787E",
  },
  dropzoneContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dropzone: {
    borderRadius: "10px",
  },
  dropzoneInnerBox: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: 3,
    margin: "15px",
    border: "1px solid #D1DCFE",
  },
  dropzoneTextStyles: {
    color: "#BBBBBB",
  },
  addFileTextBox: {
    color: "darkblue",
    marginBottom: "5px",
  },
  documentCard: {
    border: "1px solid #D1DCFE",
    borderRadius: "12px",
    boxShadow: 0,
    p: 2,
  },
  cursor: {
    // ...centerItemFlex,
    flexDirection: "column",
    cursor: "pointer",
  },
  radioBox: {
    // padding: "2px 2px",
    // height: "45px",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "#E5E7EB",
    display: "flex",
    alignItems: "center",
    background: "white",
    justifyContent: "flex-start",
  },
  radioText: {
    color: "#9F9F9F",
  },
};

export default addApplicantStyles;
