import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTopComponent = () => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      //componentDidMount
      mounted.current = true;
    } else {
      //componentDidUpdate
      window.scrollTo(0, 0);
    }
  });

  return null;
};

export const ScrollToTop = withRouter(ScrollToTopComponent);
