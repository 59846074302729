import { selectLayout } from "redux/preferencesSlice";
import { useAppSelector } from "utils/hooks";
import TopNavLayout from "./TopNavLayout/TopNavLayout";
import DrawerLayout from "./DrawerLayout/DrawerLayout";

interface CustomProps {
  children: any;
}

const Layout = (props: CustomProps) => {
  const layout = useAppSelector(selectLayout);

  switch (layout) {
    case "horizontal":
      return <TopNavLayout>{props.children}</TopNavLayout>;
    case "vertical":
      return <DrawerLayout>{props.children}</DrawerLayout>;
    default:
      return <DrawerLayout>{props.children}</DrawerLayout>;
  }
};

export default Layout;
