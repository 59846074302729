import {
  Box,
  Card,
  CardHeader,
  Rating,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import viewCandidateStyles from "../ViewApplicant.styles";
import { Subcontractor } from "../../applicantTypes";
import { isTruthy } from "helpers/methods";
import CustomTable from "global/components/CustomTable/CustomTable";

interface CustomProps {
  candidateData: Subcontractor;
  loader: boolean;
  setLoader: Function;
}

const commentTableHeaders = [
  {
    name: "Project Name",
    field: "name",
    align: "left",
  },
  {
    name: "Location",
    field: "location",
    align: "left",
  },
  {
    name: "Owner Name",
    field: "ownerName",
    align: "left",
  },
  {
    name: "Bid",
    field: "bid",
    align: "left",
  },
  {
    name: "Won",
    field: "won",
    align: "left",
  },
  {
    name: "Price Rating",
    field: "priceRating",
    align: "left",
  },
  {
    name: "Work Rating",
    field: "workRating",
    align: "left",
  },
];

const XlProject = (props: CustomProps) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [formFields, setFormFields] = useState<any>({
    value: props.candidateData?.xlProject ?? [],
    error: "",
  });

  useEffect(() => {
    isTruthy(props.candidateData!) &&
      setFormFields({
        value: props.candidateData?.xlProject ?? formFields.value,
        error: "",
      });
  }, [props.candidateData!]);

  const handleRowHover = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    setHoveredRowIndex(null);
  };

  const convertCommentsToTable = (data: any) => {
    return data.map((comment: any, index: number) => {
      return {
        name: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.name}
            </Typography>
            <Typography variant="body2">{comment.number}</Typography>
          </Box>
        ),
        location: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.location}
            </Typography>
          </Box>
        ),
        ownerName: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.ownerName}
            </Typography>
          </Box>
        ),
        bid: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.isBid ? "Yes" : "No"}
            </Typography>
          </Box>
        ),
        won: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.isWon ? "Yes" : "No"}
            </Typography>
          </Box>
        ),
        priceRating: (
          <Box sx={viewCandidateStyles.labelText}>
            <Rating
              name="priceRating"
              precision={0.5}
              value={comment.priceRating}
              readOnly
            />
          </Box>
        ),
        workRating: (
          <Box sx={viewCandidateStyles.labelText}>
            <Rating
              name="priceRating"
              precision={0.5}
              value={comment.workRating}
              readOnly
            />
          </Box>
        ),
      };
    });
  };

  return (
    <>
      <Card>
        <CardHeader title="XL Projects worked on" />
        <Box mt={1}>
          <CustomTable
            headers={commentTableHeaders}
            rows={convertCommentsToTable(formFields.value)}
            showPagination={false}
            onRowHover={handleRowHover}
            onRowLeave={handleRowLeave}
            isLoading={props.loader}
          />
        </Box>
      </Card>
    </>
  );
};

export default XlProject;
