
import defaultImage from "assets/icons1/avatars/default.png";

export const Avatars = [
  defaultImage,

];

export const Avatar = {
  // male: [male1, male2, male3, male4],
  // female: [female1, female2, female3, female4],
};

export const getRandomNumberBetweenRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};



export const getAvatarByIndex = (index: number) => {
  return Avatars[index];
};

export const getRandomAvatarByGender = (gender: string) => {
      return defaultImage;
};
