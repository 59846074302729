import { ButtonBase, SvgIcon, Collapse, Box } from "@mui/material";
import { RouterLink } from "components/router-link";
import React, { useCallback, useState } from "react";
import type { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface CustomProps {
  active?: boolean;
  children?: ReactNode;
  depth?: number;
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  label?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const MobileNavItem = (props: CustomProps) => {
  const [open, setOpen] = useState<boolean>(!!props.open);

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

  let startIcon: ReactNode;

  if (props.depth === 0) {
    if (props.active) {
      startIcon = props.activeIcon;
    } else {
      startIcon = props.icon;
    }
  } else {
    startIcon = (
      <Box
        sx={{
          alignItems: "center",
          display: "center",
          height: 20,
          justifyContent: "center",
          width: 20,
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: "var(--nav-item-icon-color)",
            height: 4,
            opacity: 0, // remove this if you want it to be visible
            width: 4,
            ...(props.active && {
              backgroundColor: "var(--nav-item-icon-active-color)",
              height: 6,
              opacity: 1,
              width: 6,
            }),
          }}
        />
      </Box>
    );
  }

  const offset = props.depth ?? 0 ? 0 : (props.depth! - 1) * 16;

  // Branch

  if (props.children) {
    return (
      <li>
        <ButtonBase
          disabled={props.disabled}
          onClick={handleToggle}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            justifyContent: "flex-start",
            pl: `${16 + offset}px`,
            pr: "16px",
            py: "6px",
            textAlign: "left",
            width: "100%",
            ...(props.active && {
              ...(props.depth === 0 && {
                backgroundColor: "var(--nav-item-active-bg)",
              }),
            }),
            "&:hover": {
              backgroundColor: "var(--nav-item-hover-bg)",
            },
          }}
        >
          {startIcon && (
            <Box
              component="span"
              sx={{
                alignItems: "center",
                color: "var(--nav-item-icon-color)",
                display: "inline-flex",
                justifyContent: "center",
                mr: 2,
                ...(props.active && {
                  color: "var(--nav-item-icon-active-color)",
                }),
              }}
            >
              {startIcon}
            </Box>
          )}
          <Box
            component="span"
            sx={{
              color: "var(--nav-item-color)",
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: 16,
              fontWeight: 400,
              lineHeight: "24px",
              whiteSpace: "nowrap",
              ...(props.active && {
                color: "var(--nav-item-active-color)",
                fontFamily: (theme) => theme.typography.fontFamily,
                fontWeight: 500,
                fontSize: 16,
              }),
              ...(props.disabled && {
                color: "var(--nav-item-disabled-color)",
              }),
            }}
          >
            {props.title}
          </Box>
          <SvgIcon
            sx={{
              color: "var(--nav-item-chevron-color)",
              fontSize: 16,
              ml: 2,
            }}
          >
            {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </ButtonBase>
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {props.children}
        </Collapse>
      </li>
    );
  }

  // Leaf

  const linkProps = props.path
    ? props.external
      ? {
          component: "a",
          href: props.path,
          target: "_blank",
        }
      : {
          component: RouterLink,
          href: props.path,
        }
    : {};

  return (
    <li>
      <ButtonBase
        disabled={props.disabled}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: `${25 + offset}px`,
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(props.active && {
            ...(props.depth === 0 && {
              backgroundColor: "var(--nav-item-active-bg)",
            }),
          }),
          "&:hover": {
            backgroundColor: "var(--nav-item-hover-bg)",
          },
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "var(--nav-item-icon-color)",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              ...(props.active && {
                color: "var(--nav-item-icon-active-color)",
              }),
            }}
          >
            {startIcon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "var(--nav-item-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "24px",
            whiteSpace: "nowrap",
            ...(props.active && {
              color: "var(--nav-item-active-color)",
              fontFamily: (theme) => theme.typography.fontFamily,
              fontWeight: 500,
              fontSize: 16,
            }),
            ...(props.disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {props.title}
        </Box>
        {props.label && (
          <Box component="span" sx={{ ml: 2 }}>
            {props.label}
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

export default MobileNavItem;
