import history from "utils/history";
import urls from "global/constants/UrlConstants";
import { store } from "utils/store";
import { logOutAction, updateAccessToken } from "redux/authSlice";
import strings from "global/constants/StringConstants";
import { handleErrorMessage, isTruthy } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { jwtDecode } from "jwt-decode";
import viewpaths from "global/constants/ViewPathConstants";

// Checks if the error code is 401 or 403 -> Logout the user
export const checkStatus = (error: any) => {
  if (error.status === 401) {
    store.dispatch(logOutAction({}));
    history.push(viewpaths.landingViewPath);
    return true;
  } else return false;
};

// Authenticated Call Headers For MFA
export const getMFACallParams = async (
  token: string,
  methodType: string,
  body?: any,
  signal?: any
) => {
  return {
    method: methodType,
    headers: await getHeaderObject(token, token, strings.applicationJSON),
    body: JSON.stringify(body),
    signal,
  };
};

// Authenticated Call Headers
export const getCallParams = async (
  methodType: string,
  body?: any,
  signal?: any
) => {
  const accessToken = store.getState().auth.accessToken;
  const refreshToken = store.getState().auth.refreshToken;

  return {
    method: methodType,
    headers: await getHeaderObject(
      accessToken,
      refreshToken,
      strings.applicationJSON
    ),
    body: JSON.stringify(body),
    signal,
  };
};

//Authenticated search Headers
export async function getSearchedCallParams(
  methodType: string,
  body?: any,
  signal?: any
) {
  const accessToken = store.getState().auth.accessToken;
  const refreshToken = store.getState().auth.refreshToken;

  return {
    method: methodType,
    headers: await getHeaderObject(
      accessToken,
      refreshToken,
      strings.applicationJSON
    ),
    body: body,
    signal,
  };
}

// Unauthenticated Call Headers
export const getNoAuthCallParams = (
  methodType: string,
  body?: any,
  signal?: any
) => {
  return {
    method: methodType,
    headers: strings.applicationJSON,
    body: JSON.stringify(body),
    signal,
  };
};

export async function getAccessToken(
  methodType: string,
  body?: any,
  token?: string
) {
  const accessToken = token!;
  return {
    method: methodType,
    headers: await getHeaderObject(
      accessToken,
      accessToken,
      strings.applicationJSON
    ),
    body: JSON.stringify(body),
  };
}

export const getFileCallParams = async (
  method: string,
  body: any,
  signal?: any
) => {
  const accessToken = store.getState().auth.accessToken;
  const refreshToken = store.getState().auth.refreshToken;

  return {
    method,
    headers: await getHeaderObject(
      accessToken,
      refreshToken,
      strings.multipartForm
    ),
    body: body,
    signal,
  };
};

export const getDeleteCallParams = async (body: any = {}, signal?: any) => {
  const accessToken = store.getState().auth.accessToken;
  const refreshToken = store.getState().auth.refreshToken;
  return {
    method: "POST",
    headers: await getHeaderObject(
      accessToken,
      refreshToken,
      strings.applicationJSON
    ),
    body: JSON.stringify(body),
    signal,
  };
};

export const getNoAuthFileCallParams = (body: any, signal?: any) => {
  return {
    method: "POST",
    headers: strings.multipartForm,
    body: body,
    signal,
  };
};

export async function getHeaderObject(
  accessToken: string,
  refreshToken: string,
  contentType: any
) {
  try {
    const verifiedAccessToken: string | void = await checkIdToken(
      accessToken,
      refreshToken
    );
    if (verifiedAccessToken) {
      return {
        ...contentType,
        Authorization: "Bearer " + verifiedAccessToken,
      };
    }
    return null;
  } catch (error: any) {
    throw error;
  }
}

// Triggers the api call
// If the api call takes more time than timeout, then it will timeout the call.
export const makeCall = async (
  callName: string,
  callParams: any,
  convertToJSON: boolean = true
) => {
  let statusCode;
  try {
    let call = fetch(callName, callParams);
    let timeout = getTimeoutPromise();

    const response: any = await Promise.race([timeout, call]).catch((error) => {
      throw error;
    });
    let json;
    statusCode = response.status;
    if (convertToJSON) {
      json = await response.json();
    }
    if (response && response.ok) {
      return json;
    }
    throw json;
  } catch (error: any) {
    error.statusCode = statusCode;
    throw error;
  }
};

export const getTimeoutPromise = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          error: true,
          message: "Something went wrong. Please reload the page.",
        }),
      50000 // 30 Seconds
    );
  });
};

export const fetchDocument = async (
  url: string,
  methodType: string,
  bodyObject?: any
) => {
  let statusCode;
  try {
    const requestOption = await makeCall(methodType, bodyObject);
    const response = await fetch(url, requestOption);
    statusCode = response.status;
    if (response.ok) {
      return response.blob();
    } else {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        const error = (data && data.errorMessage) || response.statusText;
        return Promise.reject(error);
      });
    }
  } catch (error: any) {
    error.statusCode = statusCode;
    error.errorMessage = handleErrorMessage(error);
    throw error;
  }
};

export const makeCallFile = async (callName: string, callParams: any) => {
  let statusCode;
  try {
    let call = fetch(callName, callParams);
    let timeout = getTimeoutPromise();
    const response: any = await Promise.race([timeout, call]).catch((err) => {
      throw err;
    });
    statusCode = response.status;
    if (response && response.ok) {
      return response.blob();
    } else {
      throw response.blob();
    }
  } catch (error: any) {
    error.statusCode = statusCode;
    error.errorMessage = handleErrorMessage(error);
    throw error;
  }
};

export async function checkIdToken(accessToken: string, refreshTok: string) {
  if (accessToken) {
    const decodedIdToken: any = jwtDecode(accessToken);
    const decodedRefreshToken: any = jwtDecode(refreshTok);
    let userEmail = decodedIdToken["ss:e"];

    if (decodedIdToken["exp"] * 1000 <= Date.now()) {
      if (userEmail && decodedRefreshToken["exp"] * 1000 < Date.now()) {
        history.push(viewpaths.loginViewPath);
        store.dispatch(logOutAction({}));
        throw {
          error: true,
          message:
            "You are logged out due to inactivity. Please login to continue.",
        };
      }
      try {
        const newUserIdToken = await refreshToken(refreshTok, accessToken);
        return newUserIdToken;
      } catch (error: any) {
        throw error;
      }
    }
    return accessToken;
  }
}

export async function refreshToken(refreshTok: string, accessToken: string) {
  try {
    const body = {
      refreshToken: refreshTok,
      idToken: accessToken,
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.REFRESHTOKEN, callParams);
    store.dispatch(
      updateAccessToken({
        accessToken: response.authToken,
      })
    );
    return response.authToken;
  } catch (error: any) {
    if (!isTruthy(error.message)) {
      throw {
        error: true,
        message: notifiers.GENERIC_ERROR,
      };
    }
    throw error;
  }
}
