import { backdropClasses } from "@mui/material/Backdrop";
import { filledInputClasses } from "@mui/material/FilledInput";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { paperClasses } from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { common } from "@mui/material/colors";
import type { Components } from "@mui/material/styles/components";
import type {
  PaletteColor,
  PaletteOptions,
} from "@mui/material/styles/createPalette";
import { alpha } from "@mui/material/styles";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.neutral![300],
          color: common.black,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: alpha(common.black, 0.5),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              "0px 5px 22px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.06)",
            backgroundColor: "#111927",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action!.active,
        },
        root: {
          borderColor: palette.neutral![700],
          backgroundColor: "#f25822",
          color: "#ffff",
          borderRadius: "10px",
          border: "1px",
          fontWeight: 400,
          fontSize: "0.875rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: (palette.primary as PaletteColor).main,
        },
        ".slick-dots li button": {
          "&:before": {
            fontSize: 10,
            color: (palette.primary as PaletteColor).main,
          },
        },
        ".slick-dots li.slick-active button": {
          "&:before": {
            color: (palette.primary as PaletteColor).main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.57,
          "&::placeholder": {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            color: "#AFB4BC",
            opacity: 1,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: palette.divider,
          "&:hover": {
            backgroundColor: palette.action!.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
            borderColor: (palette.primary as PaletteColor).main,
            boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`,
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
            boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.57,
          "&::placeholder": {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            color: "#AFB4BC",
            opacity: 1,
          },
        },
        root: {
          "&:hover": {
            backgroundColor: palette.action!.hover,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.divider,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#2D3751",
              // borderColor: (palette.primary as PaletteColor).main,
              borderWidth: "1px solid",
            },
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: (palette.error as PaletteColor).main,
              borderWidth: "1px solid",
            },
          },
        },
        notchedOutline: {
          borderColor: palette.divider,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#f25822",
          "&.Mui-checked": {
            color: "#f25822",
            opacity: 1,
          },
          "&.Mui-checked+.MuiSwitch-track": {
            backgroundColor: "#E7F4D7",
            opacity: 1,
          },
          // color: palette.neutral![500],
        },
        track: {
          backgroundColor: "#E7F4D7",
          // backgroundColor: palette.neutral![400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: palette.neutral![800],
            color: palette.neutral![400],
          },
        },
      },
    },
    // @ts-ignore
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: palette.divider,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backdropFilter: "blur(6px)",
          background: "#f25822",
          fontSize: "0.625rem",
          fontWeight: 400,
          // backdropFilter: "blur(6px)",
          // background: alpha(palette.neutral![900], 0.8),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: "1rem",
          fontWeight: 400,
          background: "#f25822",
          borderRadius: "6px",
          color: "#FFFFFF",
          padding: "6px 16px",
          "&:hover": {
            background: "#f25822",
          },
        },
        outlined: {
          border: "1px solid #626876",
          fontSize: "1rem",
          fontWeight: 400,
          borderRadius: "6px",
          background: "#0e1320",
          color: "#f25822",
          padding: "6px 16px",
          "&:hover": {
            border: "1px solid #626876",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          color: "#BABEC3",
          "& + &": {
            marginLeft: 24,
          },
          "&.Mui-selected": {
            fontWeight: 500,
            color: "#f25822",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#f25822",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "0.875rem",
          color: "#FFFFFF",
        },
        asterisk: {
          color: "#ff0000",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "12px",
          "&:hover": {
            backgroundColor: palette.action!.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
            // boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          background: "#f25822",
          color: "#FFFFFF",
          fontWeight: 400,
          fontSize: "0.688rem",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#f25822",
          },
          "&.Mui-active": {
            backgroundColor: "#f25822",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiRadio-root.Mui-checked ": {
            color: "#f25822",
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#15202B",
        },
      },
    },
  };
};
