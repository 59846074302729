export class ViewPathConstants {
  // Root
  rootViewPath = "/";

  // Public Routes
  landingViewPath = "/";
  loginViewPath = "/login";
  forgotPasswordViewPath = "/forgot-password";
  otpViewPath = "/verify-otp";
  verifyTOTPViewPath = "/verify-code";
  setupMFAViewPath = "/setup-mfa";
  resetPasswordViewPath = "/changepwd";

  pageNotFoundViewPath = "/page-not-found";

  // Authenticated Routes
  dashboardViewPath = "/dashboard";
  subcontractorViewPath = "/subcontractor";
  addSubcontractorViewPath = `${this.subcontractorViewPath}/add`;
  viewSubcontractorViewPath = `${this.subcontractorViewPath}/view`;
  viewSubcontractorCommentViewPath = `${this.subcontractorViewPath}/view/comment`;
  editSubcontractorViewPath = `${this.subcontractorViewPath}/edit`;
  settingsViewPath = "/settings";
  addUserViewPath = `${this.settingsViewPath}/user/add`;
  editUserViewPath = `${this.settingsViewPath}/user/edit`;
  addRolesViewPath = `${this.settingsViewPath}/roles/add`;
  editRolesViewPath = `${this.settingsViewPath}/roles/edit`;
  profileViewPath = "/profile";
  editProfileViewPath = `${this.profileViewPath}/edit`;
}

const viewpaths = new ViewPathConstants();
export default viewpaths;
