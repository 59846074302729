import moment from "moment";
import { isPhoneValid, isTruthy, openErrorNotification } from "helpers/methods";
import { FormFields } from "models/interfaces";
import strings from "global/constants/StringConstants";
import { Fields } from "./addApplicantTypes";

export const validateForBasicInfoForm = (personalDataForm: FormFields) => {
  const emailRegex = strings.regex;

  let errors = personalDataForm;
  let isValid = true;
  const name = isTruthy(personalDataForm.name.value.trim());
  const field = isTruthy(personalDataForm.field.value);
  const phone = personalDataForm.phone.value;
  const email = isTruthy(personalDataForm.email.value);
  const address = isTruthy(personalDataForm.address.value);

  if (!name && !field) {
    errors.name.error = "Please enter company name";
    errors.field.error = "Please enter trade";
    isValid = false;
  }
  if (!name) {
    errors.name.error = "Please enter company name";
    isValid = false;
  }
  if (!field) {
    errors.field.error = "Please enter trade";
    isValid = false;
  }
  // if (phone) {
  //   const re: any = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  //   if (!re.test(phone)) {
  //     errors.phone.error = "Phone Number must be of 10 digits!";
  //     isValid = false;
  //   }
  // }
  if (!isPhoneValid(phone)) {
    errors.phone.error = "Please enter valid office number";
    isValid = false;
  }
  if (email && !emailRegex.test(personalDataForm.email.value)) {
    errors.email.error = "Please enter valid email";
    isValid = false;
  }

  return { isValid, errors };
};
