import { Box, Button, Stack, Typography } from "@mui/material";
import { CustomInput } from "global/components";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  handleDialogClose: Function;
  isVisible: boolean;
  handleOnChange: Function;
  edit: boolean;
  newComment: string;
  setNewComment: Function;
  formFields: any;
  setFormFields: Function;
  isLoading: boolean;
}

const AddEditCommentModal = (props: CustomProps) => {
  const handleComment = (event: any) => {
    props.setNewComment(event.target.value);
    props.setFormFields({ ...props.formFields, error: "" });
  };

  const getBody = () => {
    return (
      <Box sx={{ width: "100%" }} mt={3}>
        <CustomInput
          id="comments"
          placeHolder="Add comment here"
          type="text"
          name="comments"
          onChange={handleComment}
          value={props.newComment}
          propsToInputElement={{ minLength: 1, maxLength: 1500 }}
          error={!isTruthy(props.newComment) && props.formFields.error}
          helperText={props.formFields.error}
        />
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Button
          id="cancel_comment"
          variant="outlined"
          size="small"
          color="inherit"
          onClick={() => {
            // props.setEdit(false);
            // props.setNewComment("");
            props.setFormFields({
              value: props.formFields.value ?? [],
              error: "",
            });
            props.handleDialogClose();
          }}
          disabled={props.isLoading}
        >
          Cancel
        </Button>
        <Button
          id="save_add_comment"
          variant="contained"
          size="small"
          onClick={() => {
            props.handleOnChange();
          }}
          disabled={props.isLoading}
        >
          {props.edit ? "Save" : "Add"}
        </Button>
      </Stack>
    );
  };

  return (
    <CustomDialog
      handleDialogClose={props.handleDialogClose}
      isDialogOpen={props.isVisible}
      body={getBody()}
      footer={getFooter()}
      title={
        <Typography variant="h5">
          {props.edit ? "Edit Comment" : "Add Comment"}
        </Typography>
      }
      closeButtonVisibility
      closable
      maxWidth="xs"
    />
  );
};

export default AddEditCommentModal;
