import {
  Autocomplete,
  Box,
  FormHelperText,
  SxProps,
  TextField,
} from "@mui/material";
import { isTruthy } from "helpers/methods";
import CustomAutoCompleteStyles from "global/components/CustomAutoComplete/CustomAutoComplete.styles";

export interface CustomProps {
  options: any;
  sx?: SxProps;
  id: string;
  placeHolder?: string;
  onChange: Function;
  onChangeForTextField?:Function;
  errorMessage?: string;
  value: any;
  disabled?: boolean;
  name?: string;
  loading?: boolean;
  clearIcon?: boolean;
  filterOptions?: any;
  type?: string;
  inputValue?: string;
  multiple?: boolean;
  groupBy?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  renderTags?: (value: string[], getTagProps: any) => React.ReactNode;
  renderOption?: (props: any, option: any, state: any) => React.ReactNode;
  freeSolo?:boolean
}

const CustomAutoComplete = (props: CustomProps) => {
  const classes = CustomAutoCompleteStyles;

  return (
    <Box>
      <Autocomplete
        multiple={props.multiple}
        id={props.id}
        freeSolo={props.freeSolo}
        componentName={props.name}
        inputValue={props.inputValue}
        loading={props?.loading}
        sx={props.sx ? props.sx : classes.autoComplete}
        value={props.value}
        clearIcon={props.clearIcon}
        options={props.options}
        disabled={props.disabled}
        filterOptions={props.filterOptions}
        onChange={(event: any, newValue: any, index: any) =>
          props.onChange(event, newValue, index)
        }
        fullWidth
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={props.placeHolder}
            error={isTruthy(props.errorMessage)}
            fullWidth
            onChange={(event: any) =>
              props.onChangeForTextField && props.onChangeForTextField(event)
            }
            onKeyDown={(event: any) => {
               if (
                 event.key === "Backspace" ||
                 event.key === "Delete" ||
                 event.key === "ArrowLeft"
               ) {
                 event.stopPropagation();
               }
            }}
          />
        )}
        getOptionLabel={props.getOptionLabel}
        groupBy={props.groupBy}
        renderTags={props.renderTags}
        renderOption={props.renderOption}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
      />
      {props.errorMessage && (
        <FormHelperText error sx={{ paddingLeft: "14px" }}>
          {props.errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CustomAutoComplete;
