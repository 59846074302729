import { Box, Snackbar, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import strings from "global/constants/StringConstants";
import { CustomIcon } from "..";

interface CustomProps {
  showNotification: boolean;
  verticalPosition: "top" | "bottom";
  horizontalPosition: "left" | "right";
  variant: string;
  title: string;
  message: string;
  icon: JSX.Element;
  handleClose: any;
}

const getStyles = (variant: string) => {
  switch (variant) {
    case strings.info:
      return {
        backgroundColor: "#dbeaf5",
        color: "#4996CE !important",
        border: "1px solid #4996CE",
      };
    case strings.success:
      return {
        backgroundColor: "#d7f4ea",
        color: "#35c994 !important",
        border: "1px solid #35c994",
      };
    case strings.warning:
      return {
        backgroundColor: "#FFE4CC",
        color: "#ffa42d !important",
        border: "1px solid #ffa42d",
      };
    case strings.error:
      return {
        backgroundColor: "#fad7da",
        color: "#e73644 !important",
        border: "1px solid #f0868f",
      };
    default:
      break;
  }
};

const CustomNotification = (props: CustomProps) => {
  const styles = getStyles(props.variant);
  const customClassesStyle = {
    notificationBlock: {
      ...styles,
      padding: "16px",
      borderRadius: "10px",
      maxWidth: "350px",
    },
  } as const;

  const customClasses = customClassesStyle;
  return (
    <Snackbar
      open={props.showNotification}
      anchorOrigin={{
        vertical: props.verticalPosition,
        horizontal: props.horizontalPosition,
      }}
      autoHideDuration={strings.autoHideDuration}
      onClose={props.handleClose}
    >
      <Stack
        sx={customClasses.notificationBlock}
        gap={1}
        alignItems="flex-start"
        direction="column"
      >
        <Box>{props.icon}</Box>
        <Box>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {props.message}
          </Typography>
        </Box>
        <Box sx={{ cursor: "pointer" }} onClick={props.handleClose}>
          <CustomIcon
            icon={<Close fontSize="small" htmlColor={styles?.color} />}
          />
        </Box>
      </Stack>
    </Snackbar>
  );
};

export default CustomNotification;
