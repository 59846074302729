import { Box, Button, Stack, Typography } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import viewApplicantStyles from "../ViewApplicant.styles";

interface CustomProps {
  handleDialogClose: Function;
  isVisible: boolean;
  handleOnChange: Function;
  isLoading: boolean;
}

const DeleteCommentModal = (props: CustomProps) => {
  const classes = viewApplicantStyles;

  const getBody = () => {
    return (
      <Box mt={3}>
        <Typography variant="body1" sx={classes.titleStyle}>
          Are you sure you want to delete the comment?
        </Typography>
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Button
          id="delete_comment_no"
          size="small"
          variant="outlined"
          color="inherit"
          onClick={() => props.handleDialogClose()}
          disabled={props.isLoading}
        >
          No
        </Button>
        <Button
          id="delete_comment_yes"
          size="small"
          variant="contained"
          onClick={() => props.handleOnChange()}
          disabled={props.isLoading}
        >
          Yes
        </Button>
      </Stack>
    );
  };

  return (
    <CustomDialog
      handleDialogClose={props.handleDialogClose}
      isDialogOpen={props.isVisible}
      body={getBody()}
      footer={getFooter()}
      title={<Typography variant="h5">Delete Comment</Typography>}
      closeButtonVisibility
      closable
      maxWidth="xs"
    />
  );
};

export default DeleteCommentModal;
