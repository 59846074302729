import type { PaletteColor } from "@mui/material/styles/createPalette";
import type { ColorPreset } from ".";
import { xlC } from "./colors";

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case "xlC":
      return xlC;
    default:
      return xlC;
  }
};
