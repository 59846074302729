import React, { useMemo } from "react";
import { Drawer, Box, Stack } from "@mui/material";
import { Section, useAuthRouteRedirection } from "../../Config";
import { usePathname } from "hooks/use-pathname";
import MobileNavSection from "./MobileNavSection";
import { useTheme } from "@mui/material/styles";
import Logo from "assets/images/PrimaryLogo.svg";
import history from "utils/history";

const MOBILE_NAV_WIDTH = 280;

const useCssVars = (): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.background.default,
        "--nav-color": theme.palette.neutral[100],
        "--nav-divider-color": theme.palette.neutral[800],
        "--nav-border-color": theme.palette.neutral[700],
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.text.primary,
        "--nav-item-disabled-color": theme.palette.neutral[600],
        "--nav-item-icon-color": theme.palette.neutral[500],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[700],
        "--nav-item-chevron-color": theme.palette.neutral[700],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    } else {
      return {
        "--nav-bg": "#242A3A",
        "--nav-color": "#FFFFFF",
        "--nav-divider-color": theme.palette.divider,
        "--nav-border-color": theme.palette.neutral[100],
        "--nav-logo-border": theme.palette.neutral[100],
        "--nav-section-title-color": theme.palette.neutral[400],
        "--nav-item-color": "#5E6979",
        "--nav-item-hover-bg": "#474C5B",
        "--nav-item-active-bg": "#474C5B",
        "--nav-item-active-color": "#FFFFFF",
        "--nav-item-disabled-color": theme.palette.neutral[400],
        "--nav-item-icon-color": "#5E6979",
        "--nav-item-icon-active-color": "#FFFFFF",
        "--nav-item-icon-disabled-color": theme.palette.neutral[400],
        "--nav-item-chevron-color": theme.palette.neutral[400],
        "--nav-scrollbar-color": theme.palette.neutral[900],
      };
    }
  }, [theme]);
};

interface CustomProps {
  onClose?: () => void;
  open?: boolean;
  sections?: Section[];
}

const MobileNav = (props: CustomProps) => {
  const pathname = usePathname();
  const cssVars = useCssVars();
  const authRoute = useAuthRouteRedirection();

  return (
    <Drawer
      anchor="left"
      onClose={props.onClose}
      open={props.open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          color: "var(--nav-color)",
          width: MOBILE_NAV_WIDTH,
        },
      }}
      variant="temporary"
    >
      <Stack sx={{ height: "100%" }}>
        <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
          <Box onClick={() => history.push(authRoute)}>
            <img
              src={Logo}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#FFFFFF",
              }}
              alt="logo"
            />
          </Box>
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
            pt: 4,
          }}
        >
          {(props.sections ?? []).map((section, index) => (
            <MobileNavSection
              items={section.items}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default MobileNav;
