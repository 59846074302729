export class StringConstants {
  PROD = false;

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  fileJSON = { "Content-Type": "text/csv" };
  multipartForm = {};

  notification = "notification";
  web = "web";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  //date
  am = "AM";
  pm = "PM";

  //tabs value
  active = "Active";
  inactive = "Inactive";
  general = "General";
  documents = "Documents";
  users = "Users";
  roleManagement = "Role Management";
  security = "Security";
  onboarding = "Onboarding";
  underReview = "UnderReview";
  preferences = "Preferences";
  blacklist = "Blacklist";
  generated = "Generated";
  sent = "Sent";
  paid = "Paid";
  overdue = "Overdue";
  due = "Due";
  partiallyPaid = "PartiallyPaid";
  Account = "Account";

  OTHERGENDER = "Prefer not to disclose";

  //NOTIFICATION
  notifications = "Notifications";
  markAsRead = "Mark as Read";
  deleteAll = "Delete All";

  //buttons
  delete = "Delete";
  inactivate = "Inactivate";
  reactivate = "Reactivate";
  createRole = "Create Role";

  //Job posting - Type
  string = "String";

  // Regex
  EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  BrandColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  //Email validation Regex
  regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,100}$/;
  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // Time-Out
  SEARCH_TIME_OUT = 1000;

  //component name Pages
  SUBCONTRACTOR = "Subcontractor";

  SETTINGS = "Settings";

  //file types
  jpeg = ".jpeg";
  JPEG = ".JPEG";
  JPG = ".JPG";
  jpg = ".jpg";
  png = ".png";
  PNG = ".PNG";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";
  pdf = ".pdf";
  PDF = ".PDF";
  doc = ".doc";
  docx = ".docx";
  txt = ".txt";
  xls = ".xls";
  xlsx = ".xlsx";
  FileUrlPrefix = `https://view.officeapps.live.com/op/embed.aspx?src=`;

  //country
  UNITED_STATE = "United States";

  ALLOWED_RESUME_TYPE = [
    this.jpeg,
    this.gif,
    this.jpg,
    this.pdf,
    this.png,
    this.JPEG,
    this.JPG,
    this.PDF,
    this.PNG,
    this.txt,
  ];

  ROLE_ADMIN = "admin";
  PLACES_API_KEY = "";
}

let strings = new StringConstants();
export default strings;
