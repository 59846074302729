import type { ReactNode } from "react";
import {
  ListItemButton,
  ListItemText,
  SvgIcon,
  ButtonBase,
  Box,
} from "@mui/material";
import { Dropdown, DropdownTrigger, DropdownMenu } from "components/dropdown";
import { RouterLink } from "components/router-link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

const renderChildItems = ({
  items,
  depth = 0,
}: {
  items: Item[];
  depth?: number;
}) => {
  return items.map((item) => {
    // Branch
    if (item.items) {
      return (
        <Dropdown key={item.title}>
          <DropdownTrigger>
            <ListItemButton
              disabled={item.disabled}
              sx={{
                borderRadius: 1,
                px: 1.5,
                py: 0.5,
              }}
            >
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  sx: {
                    color: "text.secondary",
                    fontSize: 14,
                    fontWeight: 500,
                  },
                }}
              />
              <SvgIcon fontSize="small" sx={{ color: "neutral.400" }}>
                <ChevronRightIcon />
              </SvgIcon>
            </ListItemButton>
          </DropdownTrigger>
          <DropdownMenu
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            disableScrollLock
            PaperProps={{
              elevation: 8,
              sx: {
                maxWidth: "100%",
                ml: 1,
                p: 1,
                width: 200,
              },
            }}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
          >
            {renderChildItems({ items: item.items, depth: depth + 1 })}
          </DropdownMenu>
        </Dropdown>
      );
    }

    const linkProps = item.path
      ? item.external
        ? {
            component: "a",
            href: item.path,
            target: "_blank",
          }
        : {
            component: RouterLink,
            href: item.path,
          }
      : {};

    // Leaf
    return (
      <ListItemButton
        disabled={item.disabled}
        key={item.title}
        sx={{
          borderRadius: 1,
          px: 1.5,
          py: 0.5,
        }}
        {...linkProps}
      >
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            sx: {
              color: "text.secondary",
              fontSize: 14,
              fontWeight: 500,
            },
          }}
        />
      </ListItemButton>
    );
  });
};

interface CustomProps {
  active?: boolean;
  children?: ReactNode;
  depth?: number;
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const TopNavItem = (props: CustomProps) => {
  let startIcon: ReactNode;
  if (props.depth === 0) {
    if (props.active) {
      startIcon = props.activeIcon;
    } else {
      startIcon = props.icon;
    }
  } else {
    startIcon = (
      <Box
        sx={{
          alignItems: "center",
          display: "center",
          height: 20,
          justifyContent: "center",
          width: 20,
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--nav-item-icon-color)",
            borderRadius: "50%",
            height: 4,
            opacity: 0, // remove this if you want it to be visible
            width: 4,
            ...(props.active && {
              backgroundColor: "var(--nav-item-icon-active-color)",
              height: 6,
              opacity: 1,
              width: 6,
            }),
          }}
        />
      </Box>
    );
  }

  if (props.items) {
    return (
      <Dropdown>
        <DropdownTrigger>
          <li>
            <ButtonBase
              disabled={props.disabled}
              sx={{
                alignItems: "center",
                borderRadius: 1,
                display: "flex",
                justifyContent: "flex-start",
                px: "16px",
                py: "6px",
                textAlign: "left",
                width: "100%",
                ...(props.active && {
                  backgroundColor: "var(--nav-item-active-bg)",
                }),
                "&:hover": {
                  backgroundColor: "var(--nav-item-hover-bg)",
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  alignItems: "center",
                  color: props.active
                    ? "var(--nav-item-icon-active-color)"
                    : "var(--nav-item-icon-color)",
                  display: "inline-flex",
                  justifyContent: "center",
                  mr: 2,
                  ...(props.active && {
                    color: "var(--nav-item-icon-active-color)",
                  }),
                }}
              >
                {startIcon}
              </Box>
              <Box
                component="span"
                sx={{
                  color: "var(--nav-item-color)",
                  flexGrow: 1,
                  fontFamily: (theme) => theme.typography.fontFamily,
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: "24px",
                  whiteSpace: "nowrap",
                  ...(props.active && {
                    color: "var(--nav-item-active-color)",
                  }),
                  ...(props.disabled && {
                    color: "var(--nav-item-disabled-color)",
                  }),
                }}
              >
                {props.title}
              </Box>
              <SvgIcon
                sx={{
                  color: "var(--nav-item-chevron-color)",
                  fontSize: 16,
                  ml: 1,
                }}
              >
                <ExpandMoreIcon />
              </SvgIcon>
            </ButtonBase>
          </li>
        </DropdownTrigger>
        <DropdownMenu
          disableScrollLock
          PaperProps={{
            elevation: 8,
            sx: {
              maxWidth: "100%",
              p: 1,
              width: 200,
            },
          }}
        >
          {renderChildItems({ items: props.items, depth: 0 })}
        </DropdownMenu>
      </Dropdown>
    );
  }

  // Without dropdown

  const linkProps = props.path
    ? props.external
      ? {
          component: "a",
          href: props.path,
          target: "_blank",
        }
      : {
          component: RouterLink,
          href: props.path,
        }
    : {};

  return (
    <li>
      <ButtonBase
        disabled={props.disabled}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          px: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(props.active && {
            backgroundColor: "var(--nav-item-active-bg)",
          }),
          // "&:hover": {
          //   backgroundColor: "var(--nav-item-hover-bg)",
          // },
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "var(--nav-item-icon-color)",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              ...(props.active && {
                color: "var(--nav-item-icon-active-color)",
              }),
            }}
          >
            {startIcon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "var(--nav-item-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
            ...(props.active && {
              color: "var(--nav-item-active-color)",
            }),
            ...(props.disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {props.title}
        </Box>
        {props.label && (
          <Box component="span" sx={{ ml: 1 }}>
            {props.label}
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

export default TopNavItem;
