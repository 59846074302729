const authLayoutStyles = {
  root: {
    backgroundColor: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100vh",
    backgroundSize: "cover",
  },
  rootBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flex: "1 1 auto",
  },
  container: {
    py: {
      xs: "60px",
      md: "120px",
    },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    mb: 4,
  },
};
export default authLayoutStyles;
