import authLayoutStyles from "layouts/AuthLayout/AuthLayout.styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import Logo from "assets/images/PrimaryLogo.svg";
import title from "global/constants/TitleConstants";

interface CustomProps {
  children: any;
}

const AuthLayout = (props: CustomProps) => {
  const classes = authLayoutStyles;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.rootBox}>
        <Container maxWidth="sm" sx={classes.container}>
          <Box sx={classes.logoContainer}>
            <img
              src={Logo}
              style={{
                width: "150px",
                height: "50px",
                // backgroundColor: "#F4F4F4",
                border: "1px solid #E5E7EB",
                borderRadius: "20px",
                padding: "16px",
              }}
            />
          </Box>
          {props.children}
          <Stack mt={7} alignItems="center">
            <Typography variant="body2">{`© ${new Date().getFullYear()} ${
              title.App
            }. All Rights Reserved.`}</Typography>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default AuthLayout;
