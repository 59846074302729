import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  logOutAction,
  selectEmail,
  selectProfileImage,
  selectUserName,
} from "redux/authSlice";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import UserIcon from "@mui/icons-material/PermIdentity";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import history from "utils/history";
import CustomPopper from "global/components/CustomPopper/CustomPopper";
import { usePopover } from "hooks/use-popover";
import { RouterLink } from "components/router-link";
import viewpaths from "global/constants/ViewPathConstants";
import strings from "global/constants/StringConstants";
import Preferences from "assets/icons1/Preferences.svg";

const AccountPopover = () => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const userEmail = useAppSelector(selectEmail);
  const userName = useAppSelector(selectUserName);
  const dispatch = useAppDispatch();
  const popover = usePopover<any>();
  const profileImage = useAppSelector(selectProfileImage);

  const handleLogout = () => {
    dispatch(logOutAction({}));
    history.push(viewpaths.landingViewPath);
  };

  const getMenuSection = () => {
    return (
      <>
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">{userName}</Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ wordBreak: "break-word" }}
          >
            {userEmail}
          </Typography>
        </Box>
        <Divider />
        {/* <Box sx={{ p: 1 }}>
          <ListItemButton
            href={viewpaths.settingsViewPath}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <SettingsIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">General Settings</Typography>
              }
            />
          </ListItemButton>
          <ListItemButton
            href={`${viewpaths.settingsViewPath}?tab=users`}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <UserIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Users</Typography>}
            />
          </ListItemButton>
          <ListItemButton
            href={`${viewpaths.settingsViewPath}?tab=role-management`}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <ManageAccountsIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Role Management</Typography>}
            />
          </ListItemButton>
          <ListItemButton
            href={`${viewpaths.settingsViewPath}?tab=security`}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <SecurityIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Security</Typography>}
            />
          </ListItemButton>
          <ListItemButton
            href={`${viewpaths.settingsViewPath}?tab=account`}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <ManageAccountsIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Account</Typography>}
            />
          </ListItemButton>
          <ListItemButton
            href={`${viewpaths.settingsViewPath}?tab=preferences`}
            onClick={popover.handleClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            LinkComponent={RouterLink}
          >
            <ListItemIcon>
              <img src={Preferences} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Preferences</Typography>}
            />
          </ListItemButton>
        </Box> */}
        <Divider sx={{ my: "0 !important" }} />
        <Box
          sx={{
            display: "flex",
            p: 1,
            justifyContent: "center",
          }}
        >
          <Button color="inherit" onClick={() => handleLogout()} size="medium">
            Logout
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Stack direction="row">
      {lgUp && (
        <Stack sx={{ textAlign: "right" }} pr={1}>
          <Typography variant="body1">{userName}</Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ fontSize: "0.75rem" }}
          >
            {userEmail}
          </Typography>
        </Stack>
      )}
      <Box
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: mdUp ? "#343434" : "#F2F4F7",
          height: 40,
          width: 40,
          borderRadius: "50%",
          cursor: "pointer",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={profileImage}
        />
      </Box>
      <CustomPopper
        open={popover.open}
        anchorRef={popover.anchorRef.current}
        handleClose={popover.handleClose}
        children={getMenuSection()}
      />
    </Stack>
  );
};

export default AccountPopover;
