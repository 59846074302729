import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "hooks/use-app-select";
import { useRef, useState } from "react";
import { selectAuthenticated } from "redux/authSlice";
import history from "utils/history";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import { login } from "screens/Auth/AuthService";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "helpers/methods";
import urls from "global/constants/UrlConstants";
import ReCAPTCHA from "react-google-recaptcha";
import strings from "global/constants/StringConstants";
import { CustomInput } from "global/components";
import { useTitle } from "utils/UseTitle";
import title from "global/constants/TitleConstants";
import { LoginFields } from "models/interfaces";
import { useAuthRouteRedirection } from "layouts/Config";
import viewpaths from "global/constants/ViewPathConstants";

const ForgotPassword = () => {
  useTitle(title.FORGOT_PASSWORD);
  const reRef = useRef<ReCAPTCHA | null>(null);
  const isLoggedIn = useAppSelector(selectAuthenticated);
  const authRoute = useAuthRouteRedirection();
  const [processing, setProcessing] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({
    email: {
      value: "",
      error: "",
    },
  } as LoginFields);
  const emailRegex = strings.regex;

  const handleChange = (event: any) => {
    setCredentials({
      ...credentials,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleValidation = () => {
    let errors = credentials;
    let isValid = true;
    const email = credentials.email.value;
    if (!email) {
      errors.email.error = "Please enter email";
      isValid = false;
    }
    setCredentials({ ...errors });
    return isValid;
  };

  const handleForgotPassword = async () => {
    try {
      if (handleValidation()) {
        setProcessing(true);
        let captchaToken: any;
        if (urls.PROD) {
          captchaToken = await reRef.current?.executeAsync();
        }
        reRef.current && reRef.current.reset();
        // await forgotPassword(credentials.email.value, captchaToken);
        openSuccessNotification(
          "We have sent you an email with your password reset link."
        );
        history.push(viewpaths.landingViewPath);
        setProcessing(false);
      }
    } catch (error: any) {
      setProcessing(false);
      handleErrorMessage(error);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleForgotPassword();
    }
  };

  const loginScreen = () => {
    return (
      <Card elevation={16}>
        <CardHeader
          subheader={
            <Typography color="text.secondary" variant="body2">
              Enter your email and we’ll send you instructions on how to reset
              your password.
            </Typography>
          }
          sx={{ pb: 0 }}
          title={
            <Typography color="text.secondary" variant="h4">
              Reset your password
            </Typography>
          }
        />
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <CustomInput
                autoFocus
                placeHolder="Email Address"
                name="email"
                onChange={handleChange}
                type="email"
                value={credentials.email.value}
                disabled={processing}
                propsToInputElement={{ maxLength: 50 }}
                error={
                  !isTruthy(credentials.email.value) && credentials.email.error
                }
                onKeyPress={handleKeypress}
                helperText={
                  !isTruthy(credentials.email.value)
                    ? credentials.email.error
                    : ""
                }
              />
              {!emailRegex.test(credentials.email.value) &&
                credentials.email.value.length > 0 && (
                  <FormHelperText error sx={{ paddingLeft: "14px" }}>
                    Please enter valid email
                  </FormHelperText>
                )}
            </Box>
          </Stack>
          <Button
            fullWidth
            size="medium"
            sx={{ mt: 2 }}
            type="submit"
            variant="contained"
            onClick={handleForgotPassword}
            disabled={processing}
          >
            Send Instructions
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
              cursor: "pointer",
            }}
          >
            <Link
              underline="hover"
              variant="subtitle2"
              onClick={() => history.push(viewpaths.loginViewPath)}
            >
              Go back to Login Page
            </Link>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (isLoggedIn) {
    history.push(authRoute);
    return null;
  } else {
    return (
      <AuthLayout>
        {loginScreen()}
        {/* {urls.PROD && (
          <ReCAPTCHA
            sitekey={strings.RECAPTCHA_SITE_KEY}
            ref={reRef}
            size="invisible"
          />
        )} */}
      </AuthLayout>
    );
  }
};

export default ForgotPassword;
