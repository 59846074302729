import { Route, RouteProps } from "react-router-dom";
import { useHasAccess } from "layouts/Config";
import Unauthorized from "screens/Shared/Unauthorized";
import { useEffect } from "react";
import history from "utils/history";
import viewpaths from "global/constants/ViewPathConstants";

interface CustomProps extends RouteProps {
  component?: any;
  isLoggedIn: boolean;
}

const AuthGuard = (props: CustomProps) => {
  const { component: Component, isLoggedIn, path, ...rest } = props;
  const hasAccess = useHasAccess(path!.toString());

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(viewpaths.loginViewPath);
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn && hasAccess ? (
          <Component {...routeProps} />
        ) : (
          <Unauthorized />
        )
      }
    />
  );
};

export default AuthGuard;
