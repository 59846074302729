import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  handleDialogClose: any;
  closable?: boolean;
  width?: string;
  height?: string;
  isDialogOpen: boolean;
  title?: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element;
  dialogFooterClass?: any;
  dialogBodyClass?: any;
  closeButtonVisibility?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

const CustomDialog = (props: CustomProps) => {
  const getDialogTitle = () => {
    return (
      props.title !== undefined && (
        <DialogTitle
          id="customized-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <>
            {isTruthy(props.title) && (
              <Box style={{ width: "100%", color: "#989898" }}>
                {props.title}
              </Box>
            )}
            {props.closable && props.closeButtonVisibility && (
              <Box onClick={props.handleDialogClose}>
                <IconButton
                  aria-label="close"
                  sx={{ border: "1px solid #989898", p: 0.5 }}
                >
                  <CloseIcon htmlColor="#989898" fontSize="small" />
                </IconButton>
              </Box>
            )}
          </>
        </DialogTitle>
      )
    );
  };

  const getDialogBody = () => {
    return (
      <DialogContent
        sx={{
          padding: 3,
        }}
      >
        {props.body}
      </DialogContent>
    );
  };

  const getDialogFooter = () => {
    if (!isTruthy(props.footer)) {
      return null;
    }
    return (
      <DialogActions sx={{ justifyContent: "center", px: 2 }}>
        {props.footer}
      </DialogActions>
    );
  };

  const getDialog = () => {
    return (
      <>
        {getDialogTitle()}
        {getDialogBody()}
        {getDialogFooter()}
      </>
    );
  };

  return (
    <Dialog
      onClose={props.handleDialogClose}
      fullWidth
      maxWidth={props.maxWidth ?? "sm"}
      open={props.isDialogOpen}
    >
      {getDialog()}
    </Dialog>
  );
};

export default CustomDialog;
