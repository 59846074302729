import {
  Box,
  Button,
  Chip,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import CustomAutoComplete from "global/components/CustomAutoComplete/CustomAutoComplete";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  handleErrorMessage,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import { useEffect, useState } from "react";
import applicantsStyles from "../Applicants.styles";
import history from "utils/history";
import viewpaths from "global/constants/ViewPathConstants";

interface CustomProps {
  states: string[];
  speciality: string[];
  setStates: Function;
  setSpeciality: Function;
  handleDialogClose: Function;
  isVisible: boolean;
  setFilters: Function;
  filters: {
    state: string;
    city: string;
    speciality: string[];
  };
}

const ApplicantsFiltersModal = (props: CustomProps) => {
  const classes = applicantsStyles;
  const [selectedSpeciality, setSelectedSpeciality] = useState<string[]>(
    props.filters.speciality
  );
  const [selectedState, setSelectedState] = useState<string[]>(
    props.filters.state !== "" ? [props.filters.state] : []
  );
  const [cities, setCities] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string[]>(
    props.filters.city !== "" ? [props.filters.city] : []
  );

  useEffect(() => {
    setSelectedCity(props.filters.city !== "" ? [props.filters.city] : []);
    setSelectedState(props.filters.state !== "" ? [props.filters.state] : []);
    setSelectedSpeciality(props.filters.speciality);
  }, [props.filters]);

  const handleDialogClose = () => {
    setSelectedCity(props.filters.city !== "" ? [props.filters.city] : []);
    setSelectedState(props.filters.state !== "" ? [props.filters.state] : []);
    setSelectedSpeciality(props.filters.speciality);
    props.handleDialogClose();
  };

  const handleDeleteSpeciality = (type: string, chip?: string) => {
    switch (type) {
      case "state":
        setSelectedState([]);
        break;
      case "city":
        setSelectedCity([]);
        break;
      case "speciality":
        let existingSpeciality = [...selectedSpeciality];
        chip && existingSpeciality.splice(existingSpeciality.indexOf(chip), 1);
        setSelectedSpeciality(existingSpeciality);
        break;
    }
  };

  const handleSelectChange = (e: any, newValue: any, type: string) => {
    switch (type) {
      case "speciality":
        if (newValue) {
          if (selectedSpeciality.includes(newValue)) {
            openErrorNotification("You have already selected the speciality.");
          } else {
            setSelectedSpeciality([...newValue]);
          }
        }
        break;
      case "state":
        if (newValue) {
          if (selectedState.includes(newValue)) {
            openErrorNotification("You have already selected the state.");
          } else {
            setSelectedState([newValue[newValue.length - 1]]);
            setSelectedCity([]);
          }
        }
        break;
      case "city":
        if (newValue) {
          if (selectedCity.includes(newValue)) {
            openErrorNotification("You have already selected the city.");
          } else {
            setSelectedCity([newValue[newValue.length - 1]]);
          }
        }
        break;
    }
  };

  const handleApplyFilters = () => {
    props.setFilters({
      state: selectedState.length > 0 ? selectedState[0] : "",
      city: selectedCity.length > 0 ? selectedCity[0] : "",
      speciality: selectedSpeciality,
    });
    history.push(`${viewpaths.subcontractorViewPath}?page=${1}`);
    setSelectedCity([]);
    setSelectedState([]);
    setSelectedSpeciality([]);
    props.handleDialogClose();
  };

  const getOption = (data: string[], existingData?: string[]) => {
    const newOption: string[] = [];
    existingData
      ? [...data, ...existingData].map((value: string) => {
          if (!newOption.includes(value)) {
            newOption.push(value);
          }
        })
      : data.map((value: string) => {
          if (!newOption.includes(value)) {
            newOption.push(value);
          }
        });

    return newOption;
  };

  const getBody = () => {
    return (
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box mb={1}>
              <InputLabel id="state" sx={classes.nameField}>
                State
              </InputLabel>
            </Box>
            <CustomAutoComplete
              id="state-filters"
              multiple
              options={getOption(props.states)}
              getOptionLabel={(option) => option}
              renderTags={() => null}
              value={selectedState}
              onChange={(event: any, newValue: any) => {
                newValue.length === 0
                  ? handleDeleteSpeciality("state")
                  : handleSelectChange(event, newValue, "state");
              }}
              placeHolder="State"
              clearIcon={false}
            />
            <Grid item xs={12} xl={12}>
              {selectedState.length > 0 && selectedState[0] !== null && (
                <Chip
                  label={selectedState[0]}
                  sx={classes.chip}
                  onDelete={() => handleDeleteSpeciality("state")}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box mb={1}>
              <InputLabel id="city" sx={classes.nameField}>
                City
              </InputLabel>
            </Box>
            <CustomAutoComplete
              id="city-filters"
              multiple
              options={selectedState.length > 0 ? getOption(cities) : []}
              getOptionLabel={(option) => option}
              renderTags={() => null}
              value={selectedCity}
              onChange={(event: any, newValue: any) => {
                newValue.length === 0
                  ? handleDeleteSpeciality("city")
                  : handleSelectChange(event, newValue, "city");
              }}
              placeHolder="City"
              clearIcon={false}
            />
            <Grid item xs={12} xl={12}>
              {selectedCity.length > 0 && selectedCity[0] !== null && (
                <Chip
                  label={selectedCity[0]}
                  sx={classes.chip}
                  onDelete={() => handleDeleteSpeciality("city")}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box mb={1}>
              <InputLabel id="speciality" sx={classes.nameField}>
                Speciality
              </InputLabel>
            </Box>
            <CustomAutoComplete
              id="speciality-filters"
              multiple={true}
              options={getOption(props.speciality, selectedSpeciality)}
              getOptionLabel={(option) => option}
              renderTags={() => null}
              value={selectedSpeciality}
              onChange={(event: any, newValue: any) => {
                handleSelectChange(event, newValue, "speciality");
              }}
              placeHolder="Speciality"
            />
            <Grid item xs={12} xl={12}>
              {selectedSpeciality.map((chip: string, ind: number) => (
                <Chip
                  key={ind}
                  label={chip}
                  sx={classes.chip}
                  onDelete={() => handleDeleteSpeciality("speciality", chip)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <Stack direction="row" spacing={2} mt={1} mb={1}>
        <Button
          id="applicants_manual_create_btn"
          variant="outlined"
          onClick={handleDialogClose}
          size="small"
          color="inherit"
        >
          Cancel
        </Button>
        <Button
          id="applicants_manual_create_btn"
          variant="contained"
          onClick={handleApplyFilters}
          size="small"
          disabled={
            selectedCity.length === 0 &&
            selectedState.length === 0 &&
            selectedSpeciality.length === 0
          }
        >
          Apply
        </Button>
      </Stack>
    );
  };

  return (
    <>
      <CustomLoader isLoading={isLoading} />
      <CustomDialog
        handleDialogClose={handleDialogClose}
        isDialogOpen={props.isVisible}
        body={getBody()}
        footer={getFooter()}
        title={<Typography variant="h5">Apply Filters</Typography>}
        closeButtonVisibility
        closable
        maxWidth="xs"
      />
    </>
  );
};

export default ApplicantsFiltersModal;
