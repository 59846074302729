import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import strings from "global/constants/StringConstants";
import {
  debounceEventHandler,
  formatDateWithTimeInLongDateFormat,
  formattedPhoneNumber,
  handleErrorMessage,
  openWarningNotification,
} from "helpers/methods";
import SearchIcon from "assets/icons1/SearchIcon.svg";
import { getActiveCount } from "screens/Applicants/ApplicantService";
import history from "utils/history";
import { useTitle } from "utils/UseTitle";
import title from "global/constants/TitleConstants";
import CustomTable from "global/components/CustomTable/CustomTable";
import { tableHeader } from "screens/Applicants/ApplicantsConstants";
import AddIcon from "@mui/icons-material/Add";
import CenteredAuthenticatedLayout from "layouts/CenteredAuthenticated/CenteredAuthenticatedLayout";
import EditDetails from "assets/icons1/EditDetailsIcon.svg";
import ViewDetails from "assets/icons1/ViewDetailsIcon.svg";
import ViewComment from "assets/icons1/CommentIcon.svg";
import { RouterLink } from "components/router-link";
import CustomPopper from "global/components/CustomPopper/CustomPopper";
import { usePopover } from "hooks/use-popover";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { useLocation, useParams } from "react-router-dom";
import viewpaths from "global/constants/ViewPathConstants";
import ApplicantsFiltersModal from "./components/ApplicantsFiltersModal";
import applicantsStyles from "screens/Applicants/Applicants.styles";

import { SortingConfig } from "models/interfaces";

const Applicants = () => {
  useTitle(title.SUBCONTRACTOR);
  const location: any = useLocation();
  const classes = applicantsStyles;
  const { id } = useParams<{ id: string }>();
  const [activeData, setActiveData] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  let pageValue = queryParams.get("page");
  let rowPerPage = queryParams.get("rowsPerPage");
  const [jumpToPageNumber, setJumpToPageNumber] = useState<number>(1);
  const popover = usePopover<any>();
  const [searchParam, setSearchParam] = useState("");
  const searchTerm = useRef<any>("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [comments, setComments] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{ field: string; order: any }[]>(
    []
  );
  const [viewFiltersModal, setViewFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    state: string;
    city: string;
    speciality: string[];
  }>({ state: "", city: "", speciality: [] });
  const [applicantId, setApplicantId] = useState<string>("");
  const [speciality, setSpeciality] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    getActiveData(pageValue ? Math.abs(parseInt(pageValue)) : 1);
    setJumpToPageNumber(pageValue ? Math.abs(parseInt(pageValue)) : 1);
    setRowsPerPage(rowPerPage ? Math.abs(parseInt(rowPerPage)) : 10);

    return () => controller.abort();
  }, [
    pageValue,
    rowsPerPage,
    searchParam.trim(),
    sortConfig,
    filters,
    rowPerPage,
  ]);

  const handleRowsPerPage = (rowPerPage: number) => {
    setRowsPerPage(rowPerPage);
    history.replace(
      `${viewpaths.subcontractorViewPath}?page=${1}&rowsPerPage=${rowPerPage}`
    );
  };

  const convertDataToTableFormat = (data: any) => {
    return data.map((applicant: any) => {
      applicant.comment = applicant.comment.filter(
        (comment: any) => comment.status === "active"
      );
      return {
        comment: (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {applicant.comment.length > 0 && (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  popover.handleOpen();
                  setApplicantId(applicant._id);
                  setAnchorEl(event.currentTarget);
                  setComments(
                    applicant?.comment.slice(
                      0,
                      applicant?.comment.length > 10
                        ? applicant?.comment.length
                        : 10
                    )
                  );
                }}
                ref={popover.anchorRef}
              >
                <Tooltip title="Comments" placement="bottom">
                  <Badge badgeContent={applicant.comment.length}>
                    <img src={ViewComment} />
                  </Badge>
                </Tooltip>
              </IconButton>
            )}
          </Stack>
        ),
        name: (
          <Stack direction="column">
            <Link
              color="inherit"
              variant="subtitle2"
              href={`${viewpaths.viewSubcontractorViewPath}/${applicant._id}`}
              component={RouterLink}
            >
              {applicant.name}
            </Link>
            <Typography sx={{ color: "#b1b2b5" }} variant="body2">
              {applicant.email}
            </Typography>
          </Stack>
        ),
        contactNumber: (
          <Stack direction="row" spacing={1} width="160px">
            {applicant?.phone?.value && applicant?.phone?.countryCode && (
              <Typography variant="body2">
                +
                {formattedPhoneNumber(
                  ` ${applicant?.phone?.countryCode} ${applicant?.phone?.value}`
                )}
              </Typography>
            )}
          </Stack>
        ),

        field: (
          <Box
            sx={{
              display: "flex",
            }}
          >
            {applicant.field}
          </Box>
        ),
        address: (
          <Box
            sx={{
              display: "flex",
            }}
          >
            {applicant.address}
          </Box>
        ),
        email: (
          <Box
            sx={{
              display: "flex",
            }}
          >
            {applicant.email}
          </Box>
        ),
        id: applicant._id,
        actions: (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Edit Subcontractor" placement="top" arrow>
              <Box
                onClick={() =>
                  history.push(
                    `${viewpaths.editSubcontractorViewPath}/${applicant._id}`
                  )
                }
                sx={{ cursor: "pointer" }}
              >
                <img src={EditDetails} />
              </Box>
            </Tooltip>
            <Tooltip title="View Subcontractor" placement="top" arrow>
              <Box
                onClick={() =>
                  history.push(
                    `${viewpaths.viewSubcontractorViewPath}/${applicant._id}`
                  )
                }
                sx={{ cursor: "pointer" }}
              >
                <img src={ViewDetails} />
              </Box>
            </Tooltip>
          </Stack>
        ),
      };
    });
  };

  const getActiveData = async (page: number) => {
    try {
      setIsLoading(true);
      const sortingParams: SortingConfig[] = getSortingParams();
      const [response] = await Promise.all([
        getActiveCount(page - 1, rowsPerPage, searchParam, sortingParams),
      ]);
      const tableData = convertDataToTableFormat(response.data);
      setActiveData([...tableData]);
      setCount(response.metadata.total);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleSearchParams = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value) {
      setSearchParam(event.target.value.trim());
      history.push(
        `${viewpaths.subcontractorViewPath}?page=${1}&rowsPerPage=${10}`
      );
    } else {
      setSearchParam("");
      history.push(
        `${viewpaths.subcontractorViewPath}?page=${1}&rowsPerPage=${10}`
      );
    }
  };

  const handlePageChange = (event: any, pageNumber: number) => {
    history.push(
      `${viewpaths.subcontractorViewPath}?page=${pageNumber}&rowsPerPage=${rowsPerPage}`
    );
    setJumpToPageNumber(pageNumber);
  };

  const getSortingParams = () => {
    return sortConfig.map((item: any) => ({
      field: item.field,
      order: item.order,
    }));
  };

  const handleOnChangePageInput = (newPage: number, count: number) => {
    setJumpToPageNumber(newPage);
    if (newPage && newPage >= 1 && newPage <= count) {
      history.replace(
        `${viewpaths.subcontractorViewPath}?page=${newPage}&rowsPerPage=${rowsPerPage}`
      );
    } else if (!newPage) {
      openWarningNotification("Please fill in the jump to page field.");
    }
  };

  const handleSort = (field: string) => {
    const existingSortIndex = sortConfig.findIndex(
      (item) => item.field === field
    );
    let newSortConfig = [...sortConfig];

    if (existingSortIndex !== -1) {
      const currentOrder = newSortConfig[existingSortIndex].order;
      let newOrder: any;

      if (currentOrder === "asc") {
        newOrder = "desc";
        newSortConfig[existingSortIndex] = {
          field,
          order: newOrder,
        };
      } else if (currentOrder === "desc") {
        newOrder = null;
        newSortConfig.splice(existingSortIndex, 1);
      } else {
        newOrder = "asc";
        newSortConfig[existingSortIndex] = {
          field,
          order: newOrder,
        };
      }
    } else {
      newSortConfig = [...newSortConfig, { field, order: "asc" }];
    }

    setSortConfig(newSortConfig);
  };

  const handleViewMoreRedirection = () => {
    history.push({
      pathname: `${viewpaths.viewSubcontractorViewPath}/${applicantId}`,
      state: {
        id: applicantId,
      },
    });
  };

  const getCommentsContent = () => {
    return (
      <Box sx={{ maxHeight: "300px" }}>
        {getComments()}
        {comments.length > 3 && (
          <Stack mt={2} alignItems="flex-end">
            <Typography
              variant="body1"
              sx={{ cursor: "pointer" }}
              onClick={handleViewMoreRedirection}
            >
              View More
            </Typography>
          </Stack>
        )}
      </Box>
    );
  };

  const getComments = () => {
    return comments.slice(0, 3).map((comment: any) => {
      return (
        <Box
          mb={2}
          sx={{
            wordBreak: "break-word",
          }}
        >
          <Stack direction="column">
            <Typography variant="body2" sx={classes.valueText}>
              {comment.text}
            </Typography>
            <Stack direction="row" display={"flex"} spacing={2}>
              <Typography flex={1} variant="body2" sx={classes.labelText}>
                {comment.by}
              </Typography>
              <Typography variant="body2" sx={classes.labelText}>
                {formatDateWithTimeInLongDateFormat(comment.on)}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      );
    });
  };

  const handleClearFilters = () => {
    setFilters({ state: "", city: "", speciality: [] });
  };

  const getPageHeader = () => {
    const handleDeleteSpeciality = (type: string, chip?: string) => {
      switch (type) {
        case "state":
          setFilters({
            ...filters,
            ["state"]: "",
          });
          break;
        case "city":
          setFilters({
            ...filters,
            ["city"]: "",
          });
          break;
        case "speciality":
          let existingSpeciality = [...filters.speciality];
          chip &&
            existingSpeciality.splice(existingSpeciality.indexOf(chip), 1);
          setFilters({
            ...filters,
            ["speciality"]: existingSpeciality,
          });
          break;
      }
    };

    return (
      <Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          alignItems={{ lg: "center" }}
          spacing={1}
          pl={3}
          pr={3}
          pt={3}
          gap={1}
        >
          <TextField
            id="applicants_search_box"
            placeholder="Search Subcontractor"
            variant="outlined"
            onChange={debounceEventHandler(
              handleSearchParams,
              strings.SEARCH_TIME_OUT
            )}
            fullWidth
            size="medium"
            inputRef={searchTerm}
            InputProps={{
              startAdornment: (
                <Stack mr={1}>
                  <img src={SearchIcon} width="30px" height="20px" />
                </Stack>
              ),
            }}
          />
          <Stack direction="row" spacing={2}>
            {/* <Button
              id="applicants_filters_btn"
              color="inherit"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setViewFiltersModal(true)}
              variant="outlined"
              size="small"
            >
              Filters
            </Button> */}
            <Button
              id="applicants_add_btn"
              variant="contained"
              size="small"
              startIcon={
                <Stack>
                  <AddIcon />
                </Stack>
              }
              onClick={() => history.push(viewpaths.addSubcontractorViewPath)}
            >
              Add
            </Button>
          </Stack>
        </Stack>

        {(filters.state !== "" ||
          filters.city !== "" ||
          filters.speciality.length > 0) && (
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={1}
            pl={3}
            pr={3}
            pt={2}
          >
            {filters.state !== "" && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
              >
                <Typography variant="body2">State:</Typography>
                <Chip
                  label={filters.state}
                  sx={{ ...classes.chip, ...classes.stateChip }}
                  onDelete={() => handleDeleteSpeciality("state")}
                />
              </Box>
            )}
            {filters.city !== "" && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
              >
                <Typography variant="body2">City:</Typography>
                <Chip
                  label={filters.city}
                  sx={{ ...classes.chip, ...classes.cityChip }}
                  onDelete={() => handleDeleteSpeciality("city")}
                />
              </Box>
            )}
            {filters.speciality.length > 0 && (
              <Box display={"flex"} flexWrap="wrap" alignItems={"flex-end"}>
                <Typography variant="body2">Speciality:</Typography>
                {filters.speciality.map((chip: string, ind: number) => (
                  <Chip
                    key={ind}
                    label={chip}
                    sx={{ ...classes.chip, ...classes.specialityChip }}
                    onDelete={() => handleDeleteSpeciality("speciality", chip)}
                  />
                ))}
              </Box>
            )}
            <Button
              size="small"
              onClick={() => handleClearFilters()}
              sx={{
                ...classes.chip,
              }}
            >
              Clear Filters
            </Button>
          </Stack>
        )}
      </Stack>
    );
  };

  const getCommentsPopOver = () => {
    return (
      <CustomPopper
        handleClose={popover.handleClose}
        anchorRef={anchorEl}
        open={popover.open}
        children={getCommentsContent()}
        width="600px"
      />
    );
  };

  const getFiltersModal = () => {
    return (
      <ApplicantsFiltersModal
        states={states}
        speciality={speciality}
        setSpeciality={setSpeciality}
        setStates={setStates}
        handleDialogClose={() => setViewFiltersModal(false)}
        isVisible={viewFiltersModal}
        setFilters={setFilters}
        filters={filters}
      />
    );
  };

  const getApplicantPage = () => {
    return (
      <CenteredAuthenticatedLayout>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4">Subcontractors</Typography>
          <Card>
            <CardContent sx={{ padding: 0 }}>
              {getPageHeader()}
              <CustomLoader isLoading={modalLoader} />
              <Box pt={3}>
                <CustomTable
                  headers={tableHeader}
                  rows={activeData}
                  page={pageValue ? Math.abs(parseInt(pageValue)) : 0}
                  rowsPerPage={rowPerPage ? Math.abs(parseInt(rowPerPage)) : 10}
                  onRowsPerPageChange={handleRowsPerPage}
                  onPageChange={handlePageChange}
                  jumpToPageNumber={jumpToPageNumber}
                  handleOnChangePageInput={handleOnChangePageInput}
                  showJumpToPageNumber={true}
                  setJumpToPageNumber={setJumpToPageNumber}
                  count={count}
                  showPagination={true}
                  isLoading={isLoading}
                  handleSort={handleSort}
                  sortConfig={sortConfig}
                />
              </Box>
            </CardContent>
            {getCommentsPopOver()}
          </Card>
        </Stack>
      </CenteredAuthenticatedLayout>
    );
  };

  return (
    <>
      {getApplicantPage()}
      {getFiltersModal()}
    </>
  );
};

export default Applicants;
