import {
  Box,
  Button,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CustomInput from "../CustomInput/CustomInput";
import { openWarningNotification } from "helpers/methods";

interface CustomProps {
  count: number;
  shape: "rounded" | "circular";
  onPageChange: Function;
  onRowsPerPageChange?: Function;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  showJumpToPageNumber?: boolean;
  jumpToPageNumber?: number;
  setJumpToPageNumber?: Function;
  handleOnChangePageInput?: Function;
}

const CustomPagination = (props: CustomProps) => {
  const count = Math.ceil(props.count / props.rowsPerPage);
  const isError =
    props.jumpToPageNumber === undefined ||
    props.jumpToPageNumber === null ||
    props.jumpToPageNumber > count ||
    props.jumpToPageNumber < 1;

  return (
    <Stack
      direction="row"
      spacing={2}
      mx={2}
      alignItems="center"
      justifyContent={props.showJumpToPageNumber ? "space-between" : "flex-end"}
    >
      {props.showJumpToPageNumber && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="subtitle2">Page:</Typography>
          <CustomInput
            sx={{
              "& .MuiInputBase-input": {
                padding: "8.5px 10px",
                borderColor: "red",
                width: "50px",
              },
              "& .MuiInputBase-input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                appearance: "none",
                MozAppearance: "none",
              },
              "& .MuiInputBase-input::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                appearance: "none",
                MozAppearance: "none",
              },
              "&:focus": {
                border: isError ? "1px solid red !important" : "none",
                padding: "-2",
                borderRadius: "13px",
              },
            }}
            id="page-input"
            placeHolder="Page"
            type="number"
            name="pageInput"
            onChange={(event: any, page: number) =>
              props.setJumpToPageNumber!(parseInt(event.target.value))
            }
            // InputProps={{ inputProps: { min: 1, max: count } }}
            value={props.jumpToPageNumber}
            error={isError}
            propsToInputElement={{ min: 1, max: count }}
          />
          <Typography variant="subtitle2">{`of ${count}`}</Typography>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (
                props.jumpToPageNumber! > count ||
                props.jumpToPageNumber === 0
              ) {
                return openWarningNotification(
                  "You're trying to access a page that doesn't exist or is beyond the available range."
                );
              }
              props.handleOnChangePageInput!(props.jumpToPageNumber, count);
            }}
          >
            <Typography variant="body2">Go</Typography>
          </Button>
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        {props.rowsPerPage && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle2">Rows per page:</Typography>
            <Select
              size="small"
              value={props.rowsPerPage}
              variant="outlined"
              onChange={(event) =>
                props.onRowsPerPageChange!(event.target.value)
              }
            >
              {props.rowsPerPageOptions.map((item: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </Stack>
        )}
        <Pagination
          page={props.page}
          count={count}
          shape={props.shape}
          siblingCount={1}
          onChange={(event, page: number) => {
            props.onPageChange(event, page);
          }}
          size="small"
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#f25822",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#f25822",
                color: "#FFF",
              },
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default CustomPagination;
