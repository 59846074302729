import withClearCache from "ClearCache";
import PageNotFound from "screens/Shared/PageNotFound";
import Notifications from "utils/notifications";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "theme";
import "index.css";
import PrivateRoute from "global/components/PrivateRoute/PrivateRoute";
import Applicants from "screens/Applicants/Applicants";
import { useAppSelector } from "hooks/use-app-select";
import { selectAuthenticated } from "redux/authSlice";
import Layout from "layouts/Layout";
import Settings from "screens/Settings/Settings";
import ViewApplicant from "screens/Applicants/ViewApplicant/ViewApplicant";
import AddApplicant from "screens/Applicants/AddApplicant/AddApplicant";
import { Route, Router, Switch } from "react-router-dom";
import history from "utils/history";
import Login from "screens/Auth/Login";
import ForgotPassword from "screens/Auth/ForgotPassword";
import viewpaths from "global/constants/ViewPathConstants";
import { useEffect } from "react";
import ErrorBoundary from "global/components/ErrorBoundary/ErrorBoundary";
import { ScrollToTop } from "utils/ScrollToTop";
import { selectTheme } from "redux/preferencesSlice";
import AuthGuard from "global/components/PrivateRoute/AuthGuard";

let mode: any;

const App = () => {
  const themePreference = useAppSelector(selectTheme);

  const theme = createTheme({
    colorPreset: "xlC",
    contrast: "normal",
    direction: "ltr",
    paletteMode: themePreference,
    responsiveFontSizes: true,
  });

  useEffect(() => {
    document.body.style.backgroundColor =
      themePreference === "dark" ? "#0e1320" : "#ffffff";
    document.body.style.color =
      themePreference === "dark" ? "#ffffff" : "#000000";
  }, [themePreference]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ClearCacheComponent mode={themePreference} />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const routes = [
  { path: viewpaths.subcontractorViewPath, component: Applicants },
  {
    path: `${viewpaths.viewSubcontractorViewPath}/:id`,
    component: ViewApplicant,
  },
  { path: viewpaths.addSubcontractorViewPath, component: AddApplicant },
  {
    path: `${viewpaths.editSubcontractorViewPath}/:id`,
    component: AddApplicant,
  },
  // { path: viewpaths.settingsViewPath, component: Settings },
];

const MainApp = () => {
  const isAuthenticated = useAppSelector(selectAuthenticated);

  const protectedRoutes = routes.map((route, index) => (
    <AuthGuard
      key={index}
      exact
      isLoggedIn={isAuthenticated}
      path={route.path}
      component={route.component}
    />
  ));

  return (
    <Box>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={[viewpaths.landingViewPath, viewpaths.loginViewPath]}
            component={Login}
          />
          <Route
            exact
            path={[viewpaths.forgotPasswordViewPath]}
            component={ForgotPassword}
          />

          <PrivateRoute
            isLoggedIn={isAuthenticated}
            path={routes.map((route) => route.path)}
            component={() => (
              <Layout>
                <Switch>{protectedRoutes}</Switch>
              </Layout>
            )}
          />
          <Route path={""} component={PageNotFound} />
        </Switch>
      </Router>
      <Notifications />
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
